/**
 * { redirect: { name: "stocks-portfolios___en" } }
 * Redirect inside middlewares is not working because of ___locale suffixes,
 *
 * this method is a workaround for that.
 */
export function Middleware_RedirectTo(redirectTo: RoutesNamesList) {
  // Returning the actual middleware
  return () => {
    return navigateTo({ name: getRouteNameWithLangSuffix(redirectTo) as any })
  }
}

import routerOptions0 from "/usr/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_2kb3gpyadf3doc6frtdheajbri/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/usr/src/apps/base/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}
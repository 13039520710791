<template>
  <UiButton
    variant="outline"
    :class="
      cn(
        'flex h-[unset] w-16 items-center gap-1.5 rounded-full bg-white p-1.5 ps-2.5 transition-[box-shadow] duration-200 hover:bg-white hover:shadow-md',
        { 'shadow-md': isMenuOpen }
      )
    "
  >
    <LucideX v-if="isMenuOpen" :size="15" />
    <LucideMenu v-else :size="17" />

    <UiCharAvatar
      class="user-profile-picture__avatar"
      v-if="isMounted && isAuthenticated"
      :text="displayName"
      :size="24"
    />

    <IconUser v-else name="user" filled />
  </UiButton>
</template>

<script setup lang="ts">
defineProps<{ isMenuOpen?: boolean }>()

const isMounted = useMounted()
const { displayName, isAuthenticated } = useUser()
</script>

export const isServer = typeof window === "undefined"

export const isProd = () => {
  return useRuntimeConfig().public.NUXT_PUBLIC_ENVIRONMENT === "prod"
}

export const isClient = !isServer

export const useConfig = () => {
  if (isServer || !process.client) {
    return process.env
  }

  return useRuntimeConfig().public
}

export const isFINQIsrael = () => {
  const cfg = useAppConfig()
  return cfg.app?.domain === "finqai.co.il"
}

export const isFINQUSA = () => {
  const cfg = useAppConfig()
  return cfg.app?.domain === "finqai.com"
}

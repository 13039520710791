import { default as indexqksRg9oJ1WMeta } from "/usr/src/apps/base/src/pages/index.vue?macro=true";
import { default as _91_46_46_46404_93dMgKFsN6Q6Meta } from "/usr/src/packages/error-handling/pages/[...404].vue?macro=true";
import { default as about4C1BTiVyMhMeta } from "/usr/src/apps/base/src/pages/about.vue?macro=true";
import { default as accessibility08XNiHGQ0oMeta } from "/usr/src/apps/base/src/pages/accessibility.vue?macro=true";
import { default as contactwZAZ1OhQATMeta } from "/usr/src/apps/base/src/pages/contact.vue?macro=true";
import { default as datasecurityk9e8JnurInMeta } from "/usr/src/apps/base/src/pages/datasecurity.vue?macro=true";
import { default as disclosureIQD52U35CiMeta } from "/usr/src/apps/base/src/pages/disclosure.vue?macro=true";
import { default as forgotpassword3Ou1x7Xjk4Meta } from "/usr/src/apps/base/src/pages/forgotpassword.vue?macro=true";
import { default as _91subId_93giIOKomImsMeta } from "/usr/src/packages/funds/pages/funds/categories/[[id]]/sub/[subId].vue?macro=true";
import { default as _91_91id_93_93tp1gATOtAhMeta } from "/usr/src/packages/funds/pages/funds/categories/[[id]].vue?macro=true";
import { default as _91_91secondaryId_93_93doHrMYdqh9Meta } from "/usr/src/packages/funds/pages/funds/categories/list/[id]/[subId]/[[secondaryId]].vue?macro=true";
import { default as indexpfTZWeLv3nMeta } from "/usr/src/packages/funds/pages/funds/categories/list/[id]/index.vue?macro=true";
import { default as categorieshwYG5zFBPqMeta } from "/usr/src/packages/funds/pages/funds/categories.vue?macro=true";
import { default as _91id_93wcADWhPbSjMeta } from "/usr/src/packages/funds/pages/funds/details/[id].vue?macro=true";
import { default as indexPUVZVGHyIjMeta } from "/usr/src/packages/funds/pages/funds/index.vue?macro=true";
import { default as indexZpg6WWboeuMeta } from "/usr/src/packages/funds/pages/funds/list/index.vue?macro=true";
import { default as indexFHfBmuIrrqMeta } from "/usr/src/packages/funds/pages/funds/manager/[[id]]/list/index.vue?macro=true";
import { default as _91_91id_93_93GQCyBsRXOLMeta } from "/usr/src/packages/funds/pages/funds/manager/[[id]].vue?macro=true";
import { default as manager6mT3ksJ8OwMeta } from "/usr/src/packages/funds/pages/funds/manager.vue?macro=true";
import { default as indexKaP2VkdS0RMeta } from "/usr/src/packages/funds/pages/funds/search/index.vue?macro=true";
import { default as fundsJniTMmEIW7Meta } from "/usr/src/packages/funds/pages/funds.vue?macro=true";
import { default as _91_91group_93_934w1d1wdPGbMeta } from "/usr/src/packages/kyc/pages/kyc/[type]/[[group]].vue?macro=true";
import { default as stepsHDNOePwTEnMeta } from "/usr/src/packages/kyc/pages/kyc/[type]/steps.vue?macro=true";
import { default as _91type_93Zgq61ZsU9DMeta } from "/usr/src/packages/kyc/pages/kyc/[type].vue?macro=true";
import { default as donePxbdkf3XIxMeta } from "/usr/src/packages/kyc/pages/kyc/done.vue?macro=true";
import { default as indexlHo5lbSUxmMeta } from "/usr/src/packages/kyc/pages/kyc/index.vue?macro=true";
import { default as loginYIEPQC6mhGMeta } from "/usr/src/apps/base/src/pages/login.vue?macro=true";
import { default as partnersnKP1uP9IicMeta } from "/usr/src/apps/base/src/pages/partners.vue?macro=true";
import { default as passwordresetawaitFAii2hbFCfMeta } from "/usr/src/apps/base/src/pages/passwordresetawait.vue?macro=true";
import { default as _91company_93iWeaW7O18lMeta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/companies/[company].vue?macro=true";
import { default as index7V9IkVqMQaMeta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/companies/index.vue?macro=true";
import { default as indexvOJnJJHuf3Meta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/index.vue?macro=true";
import { default as _91specialty_93EMJ8NGR8qiMeta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/[specialty].vue?macro=true";
import { default as index5EOUCdyNX8Meta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/index.vue?macro=true";
import { default as _91fundHash_93EFQ29CzgCKMeta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/watch/[fundHash].vue?macro=true";
import { default as indexiSIKEil1ZxMeta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/watch/index.vue?macro=true";
import { default as indexUOvEfDpeO0Meta } from "/usr/src/packages/pension/pages/pension/depot/index.vue?macro=true";
import { default as companiesOMujoLmf23Meta } from "/usr/src/packages/pension/pages/pension/depot/leading-funds/companies.vue?macro=true";
import { default as indexufVtMGX5vzMeta } from "/usr/src/packages/pension/pages/pension/depot/leading-funds/index.vue?macro=true";
import { default as depotaPbp8tJMbrMeta } from "/usr/src/packages/pension/pages/pension/depot.vue?macro=true";
import { default as _91_91fid_93_93B3CL5Ky413Meta } from "/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]]/[[fid]].vue?macro=true";
import { default as _91_91pid_93_93FAQHdTbWrNMeta } from "/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]].vue?macro=true";
import { default as indexCuvpEcNESTMeta } from "/usr/src/packages/portfolios/pages/portfolios/favorites/index.vue?macro=true";
import { default as lobbyVVzKDVXQ0XMeta } from "/usr/src/packages/portfolios/pages/portfolios/lobby.vue?macro=true";
import { default as _91_91fid_93_93olluoChfBKMeta } from "/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]]/[[fid]].vue?macro=true";
import { default as _91_91pid_93_935JUUTaXGU6Meta } from "/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]].vue?macro=true";
import { default as indexetdbuDprAYMeta } from "/usr/src/packages/portfolios/pages/portfolios/store/index.vue?macro=true";
import { default as storenavOVDyujfMeta } from "/usr/src/packages/portfolios/pages/portfolios/store.vue?macro=true";
import { default as portfoliosjxRGDJlRxLMeta } from "/usr/src/packages/portfolios/pages/portfolios.vue?macro=true";
import { default as _91_91productId_93_93hqPFitOqkYMeta } from "/usr/src/packages/pricing/pages/pricing/[[productId]].vue?macro=true";
import { default as pricingOIBELzIw3pMeta } from "/usr/src/packages/pricing/pages/pricing.vue?macro=true";
import { default as privacyW7FWgOcv9fMeta } from "/usr/src/apps/base/src/pages/privacy.vue?macro=true";
import { default as infoFL8Bz5DpZJMeta } from "/usr/src/apps/base/src/pages/profile/info.vue?macro=true";
import { default as _91_91id_93_938xxf54ZtMqMeta } from "/usr/src/apps/finq-finance/src/pages/profile/investments/funds/[[id]].vue?macro=true";
import { default as indexmc0Fx1pJFqMeta } from "/usr/src/apps/finq-finance/src/pages/profile/investments/index.vue?macro=true";
import { default as messages7UYmgPe1CQMeta } from "/usr/src/apps/finq-finance/src/pages/profile/investments/messages.vue?macro=true";
import { default as reportsQKsxN8v9rlMeta } from "/usr/src/apps/finq-finance/src/pages/profile/investments/reports.vue?macro=true";
import { default as transactionsn9eMzhgE0jMeta } from "/usr/src/apps/finq-finance/src/pages/profile/investments/transactions.vue?macro=true";
import { default as investmentsKaKYZu24t3Meta } from "/usr/src/apps/finq-finance/src/pages/profile/investments.vue?macro=true";
import { default as profilezv8G5W5pJQMeta } from "/usr/src/apps/base/src/pages/profile.vue?macro=true";
import { default as signupD0zLV4v9noMeta } from "/usr/src/apps/base/src/pages/signup.vue?macro=true";
import { default as indexmzXPjhq5EgMeta } from "/usr/src/packages/stocks/pages/stocks/index.vue?macro=true";
import { default as indexlmLBSz9fOYMeta } from "/usr/src/packages/stocks/pages/stocks/momentum/index.vue?macro=true";
import { default as negative3xD1YXaAWuMeta } from "/usr/src/packages/stocks/pages/stocks/momentum/negative.vue?macro=true";
import { default as positivecfmu6LyIYtMeta } from "/usr/src/packages/stocks/pages/stocks/momentum/positive.vue?macro=true";
import { default as indexPDJrGGTxBuMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/index.vue?macro=true";
import { default as returnsnxcTGiYrnyMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/returns.vue?macro=true";
import { default as transactionslgjJ1jRxX6Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/transactions.vue?macro=true";
import { default as _91portfolioType_93Lz5OTVGOUJMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType].vue?macro=true";
import { default as _91id_93xL6fM6Wr70Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/details/[id].vue?macro=true";
import { default as detailsxa2DsXpMPrMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/details.vue?macro=true";
import { default as indexsNto0OwKRYMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/index.vue?macro=true";
import { default as indexRIwpw4c1lXMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/index.vue?macro=true";
import { default as _91sectorId_934XIx6fVjIbMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors/[sectorId].vue?macro=true";
import { default as indexJ5i4BUbMRaMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors/index.vue?macro=true";
import { default as sectors74tcQjPqt4Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors.vue?macro=true";
import { default as snp500mPrgrMS26sMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500.vue?macro=true";
import { default as portfolioszi0VENqbE6Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios.vue?macro=true";
import { default as searchT1B2Y8KjdfMeta } from "/usr/src/packages/stocks/pages/stocks/search.vue?macro=true";
import { default as top20QVDMEgSsSmMeta } from "/usr/src/packages/stocks/pages/stocks/top20.vue?macro=true";
import { default as stocksYNZBSUl7vEMeta } from "/usr/src/packages/stocks/pages/stocks.vue?macro=true";
import { default as callback0Nh93rez4dMeta } from "/usr/src/apps/base/src/pages/subscribe/callback.vue?macro=true";
import { default as termsofusel5AzRHu0H7Meta } from "/usr/src/apps/base/src/pages/termsofuse.vue?macro=true";
import { default as trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta } from "/usr/src/apps/base/src/pages/trademark-copyright-intellectual-property.vue?macro=true";
import { default as verify2fhYk5060fMeta } from "/usr/src/apps/base/src/pages/verify.vue?macro=true";
export default [
  {
    name: indexqksRg9oJ1WMeta?.name ?? "index",
    path: indexqksRg9oJ1WMeta?.path ?? "/",
    meta: indexqksRg9oJ1WMeta || {},
    alias: indexqksRg9oJ1WMeta?.alias || [],
    redirect: indexqksRg9oJ1WMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46404_93dMgKFsN6Q6Meta?.name ?? "404___he",
    path: _91_46_46_46404_93dMgKFsN6Q6Meta?.path ?? "/he/:404(.*)*",
    meta: _91_46_46_46404_93dMgKFsN6Q6Meta || {},
    alias: _91_46_46_46404_93dMgKFsN6Q6Meta?.alias || [],
    redirect: _91_46_46_46404_93dMgKFsN6Q6Meta?.redirect,
    component: () => import("/usr/src/packages/error-handling/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46404_93dMgKFsN6Q6Meta?.name ?? "404___en",
    path: _91_46_46_46404_93dMgKFsN6Q6Meta?.path ?? "/en/:404(.*)*",
    meta: _91_46_46_46404_93dMgKFsN6Q6Meta || {},
    alias: _91_46_46_46404_93dMgKFsN6Q6Meta?.alias || [],
    redirect: _91_46_46_46404_93dMgKFsN6Q6Meta?.redirect,
    component: () => import("/usr/src/packages/error-handling/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: about4C1BTiVyMhMeta?.name ?? "about___he",
    path: about4C1BTiVyMhMeta?.path ?? "/he/about",
    meta: about4C1BTiVyMhMeta || {},
    alias: about4C1BTiVyMhMeta?.alias || [],
    redirect: about4C1BTiVyMhMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: about4C1BTiVyMhMeta?.name ?? "about___en",
    path: about4C1BTiVyMhMeta?.path ?? "/en/about",
    meta: about4C1BTiVyMhMeta || {},
    alias: about4C1BTiVyMhMeta?.alias || [],
    redirect: about4C1BTiVyMhMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: accessibility08XNiHGQ0oMeta?.name ?? "accessibility___he",
    path: accessibility08XNiHGQ0oMeta?.path ?? "/he/accessibility",
    meta: accessibility08XNiHGQ0oMeta || {},
    alias: accessibility08XNiHGQ0oMeta?.alias || [],
    redirect: accessibility08XNiHGQ0oMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/accessibility.vue").then(m => m.default || m)
  },
  {
    name: accessibility08XNiHGQ0oMeta?.name ?? "accessibility___en",
    path: accessibility08XNiHGQ0oMeta?.path ?? "/en/accessibility",
    meta: accessibility08XNiHGQ0oMeta || {},
    alias: accessibility08XNiHGQ0oMeta?.alias || [],
    redirect: accessibility08XNiHGQ0oMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/accessibility.vue").then(m => m.default || m)
  },
  {
    name: contactwZAZ1OhQATMeta?.name ?? "contact___he",
    path: contactwZAZ1OhQATMeta?.path ?? "/he/contact",
    meta: contactwZAZ1OhQATMeta || {},
    alias: contactwZAZ1OhQATMeta?.alias || [],
    redirect: contactwZAZ1OhQATMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactwZAZ1OhQATMeta?.name ?? "contact___en",
    path: contactwZAZ1OhQATMeta?.path ?? "/en/contact",
    meta: contactwZAZ1OhQATMeta || {},
    alias: contactwZAZ1OhQATMeta?.alias || [],
    redirect: contactwZAZ1OhQATMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: datasecurityk9e8JnurInMeta?.name ?? "datasecurity___he",
    path: datasecurityk9e8JnurInMeta?.path ?? "/he/datasecurity",
    meta: datasecurityk9e8JnurInMeta || {},
    alias: datasecurityk9e8JnurInMeta?.alias || [],
    redirect: datasecurityk9e8JnurInMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/datasecurity.vue").then(m => m.default || m)
  },
  {
    name: datasecurityk9e8JnurInMeta?.name ?? "datasecurity___en",
    path: datasecurityk9e8JnurInMeta?.path ?? "/en/datasecurity",
    meta: datasecurityk9e8JnurInMeta || {},
    alias: datasecurityk9e8JnurInMeta?.alias || [],
    redirect: datasecurityk9e8JnurInMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/datasecurity.vue").then(m => m.default || m)
  },
  {
    name: disclosureIQD52U35CiMeta?.name ?? "disclosure___he",
    path: disclosureIQD52U35CiMeta?.path ?? "/he/disclosure",
    meta: disclosureIQD52U35CiMeta || {},
    alias: disclosureIQD52U35CiMeta?.alias || [],
    redirect: disclosureIQD52U35CiMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/disclosure.vue").then(m => m.default || m)
  },
  {
    name: disclosureIQD52U35CiMeta?.name ?? "disclosure___en",
    path: disclosureIQD52U35CiMeta?.path ?? "/en/disclosure",
    meta: disclosureIQD52U35CiMeta || {},
    alias: disclosureIQD52U35CiMeta?.alias || [],
    redirect: disclosureIQD52U35CiMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/disclosure.vue").then(m => m.default || m)
  },
  {
    name: forgotpassword3Ou1x7Xjk4Meta?.name ?? "forgotpassword___he",
    path: forgotpassword3Ou1x7Xjk4Meta?.path ?? "/he/forgotpassword",
    meta: forgotpassword3Ou1x7Xjk4Meta || {},
    alias: forgotpassword3Ou1x7Xjk4Meta?.alias || [],
    redirect: forgotpassword3Ou1x7Xjk4Meta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: forgotpassword3Ou1x7Xjk4Meta?.name ?? "forgotpassword___en",
    path: forgotpassword3Ou1x7Xjk4Meta?.path ?? "/en/forgotpassword",
    meta: forgotpassword3Ou1x7Xjk4Meta || {},
    alias: forgotpassword3Ou1x7Xjk4Meta?.alias || [],
    redirect: forgotpassword3Ou1x7Xjk4Meta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/forgotpassword.vue").then(m => m.default || m)
  },
  {
    name: fundsJniTMmEIW7Meta?.name ?? undefined,
    path: fundsJniTMmEIW7Meta?.path ?? "/he/funds",
    meta: fundsJniTMmEIW7Meta || {},
    alias: fundsJniTMmEIW7Meta?.alias || [],
    redirect: fundsJniTMmEIW7Meta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds.vue").then(m => m.default || m),
    children: [
  {
    name: categorieshwYG5zFBPqMeta?.name ?? "funds-categories___he",
    path: categorieshwYG5zFBPqMeta?.path ?? "categories",
    meta: categorieshwYG5zFBPqMeta || {},
    alias: categorieshwYG5zFBPqMeta?.alias || [],
    redirect: categorieshwYG5zFBPqMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/categories.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91id_93_93tp1gATOtAhMeta?.name ?? "funds-categories-id___he",
    path: _91_91id_93_93tp1gATOtAhMeta?.path ?? ":id?",
    meta: _91_91id_93_93tp1gATOtAhMeta || {},
    alias: _91_91id_93_93tp1gATOtAhMeta?.alias || [],
    redirect: _91_91id_93_93tp1gATOtAhMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/categories/[[id]].vue").then(m => m.default || m),
    children: [
  {
    name: _91subId_93giIOKomImsMeta?.name ?? "funds-categories-id-sub-subId___he",
    path: _91subId_93giIOKomImsMeta?.path ?? "sub/:subId()",
    meta: _91subId_93giIOKomImsMeta || {},
    alias: _91subId_93giIOKomImsMeta?.alias || [],
    redirect: _91subId_93giIOKomImsMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/categories/[[id]]/sub/[subId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91_91secondaryId_93_93doHrMYdqh9Meta?.name ?? "funds-categories-list-id-subId-secondaryId___he",
    path: _91_91secondaryId_93_93doHrMYdqh9Meta?.path ?? "list/:id()/:subId()/:secondaryId?",
    meta: _91_91secondaryId_93_93doHrMYdqh9Meta || {},
    alias: _91_91secondaryId_93_93doHrMYdqh9Meta?.alias || [],
    redirect: _91_91secondaryId_93_93doHrMYdqh9Meta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/categories/list/[id]/[subId]/[[secondaryId]].vue").then(m => m.default || m)
  },
  {
    name: indexpfTZWeLv3nMeta?.name ?? "funds-categories-list-id___he",
    path: indexpfTZWeLv3nMeta?.path ?? "list/:id()",
    meta: indexpfTZWeLv3nMeta || {},
    alias: indexpfTZWeLv3nMeta?.alias || [],
    redirect: indexpfTZWeLv3nMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/categories/list/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93wcADWhPbSjMeta?.name ?? "funds-details-id___he",
    path: _91id_93wcADWhPbSjMeta?.path ?? "details/:id()",
    meta: _91id_93wcADWhPbSjMeta || {},
    alias: _91id_93wcADWhPbSjMeta?.alias || [],
    redirect: _91id_93wcADWhPbSjMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/details/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPUVZVGHyIjMeta?.name ?? "funds___he",
    path: indexPUVZVGHyIjMeta?.path ?? "",
    meta: indexPUVZVGHyIjMeta || {},
    alias: indexPUVZVGHyIjMeta?.alias || [],
    redirect: indexPUVZVGHyIjMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: indexZpg6WWboeuMeta?.name ?? "funds-list___he",
    path: indexZpg6WWboeuMeta?.path ?? "list",
    meta: indexZpg6WWboeuMeta || {},
    alias: indexZpg6WWboeuMeta?.alias || [],
    redirect: indexZpg6WWboeuMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/list/index.vue").then(m => m.default || m)
  },
  {
    name: manager6mT3ksJ8OwMeta?.name ?? "funds-manager___he",
    path: manager6mT3ksJ8OwMeta?.path ?? "manager",
    meta: manager6mT3ksJ8OwMeta || {},
    alias: manager6mT3ksJ8OwMeta?.alias || [],
    redirect: manager6mT3ksJ8OwMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/manager.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91id_93_93GQCyBsRXOLMeta?.name ?? "funds-manager-id___he",
    path: _91_91id_93_93GQCyBsRXOLMeta?.path ?? ":id?",
    meta: _91_91id_93_93GQCyBsRXOLMeta || {},
    alias: _91_91id_93_93GQCyBsRXOLMeta?.alias || [],
    redirect: _91_91id_93_93GQCyBsRXOLMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/manager/[[id]].vue").then(m => m.default || m),
    children: [
  {
    name: indexFHfBmuIrrqMeta?.name ?? "funds-manager-id-list___he",
    path: indexFHfBmuIrrqMeta?.path ?? "list",
    meta: indexFHfBmuIrrqMeta || {},
    alias: indexFHfBmuIrrqMeta?.alias || [],
    redirect: indexFHfBmuIrrqMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/manager/[[id]]/list/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexKaP2VkdS0RMeta?.name ?? "funds-search___he",
    path: indexKaP2VkdS0RMeta?.path ?? "search",
    meta: indexKaP2VkdS0RMeta || {},
    alias: indexKaP2VkdS0RMeta?.alias || [],
    redirect: indexKaP2VkdS0RMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: fundsJniTMmEIW7Meta?.name ?? undefined,
    path: fundsJniTMmEIW7Meta?.path ?? "/en/funds",
    meta: fundsJniTMmEIW7Meta || {},
    alias: fundsJniTMmEIW7Meta?.alias || [],
    redirect: fundsJniTMmEIW7Meta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds.vue").then(m => m.default || m),
    children: [
  {
    name: categorieshwYG5zFBPqMeta?.name ?? "funds-categories___en",
    path: categorieshwYG5zFBPqMeta?.path ?? "categories",
    meta: categorieshwYG5zFBPqMeta || {},
    alias: categorieshwYG5zFBPqMeta?.alias || [],
    redirect: categorieshwYG5zFBPqMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/categories.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91id_93_93tp1gATOtAhMeta?.name ?? "funds-categories-id___en",
    path: _91_91id_93_93tp1gATOtAhMeta?.path ?? ":id?",
    meta: _91_91id_93_93tp1gATOtAhMeta || {},
    alias: _91_91id_93_93tp1gATOtAhMeta?.alias || [],
    redirect: _91_91id_93_93tp1gATOtAhMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/categories/[[id]].vue").then(m => m.default || m),
    children: [
  {
    name: _91subId_93giIOKomImsMeta?.name ?? "funds-categories-id-sub-subId___en",
    path: _91subId_93giIOKomImsMeta?.path ?? "sub/:subId()",
    meta: _91subId_93giIOKomImsMeta || {},
    alias: _91subId_93giIOKomImsMeta?.alias || [],
    redirect: _91subId_93giIOKomImsMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/categories/[[id]]/sub/[subId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91_91secondaryId_93_93doHrMYdqh9Meta?.name ?? "funds-categories-list-id-subId-secondaryId___en",
    path: _91_91secondaryId_93_93doHrMYdqh9Meta?.path ?? "list/:id()/:subId()/:secondaryId?",
    meta: _91_91secondaryId_93_93doHrMYdqh9Meta || {},
    alias: _91_91secondaryId_93_93doHrMYdqh9Meta?.alias || [],
    redirect: _91_91secondaryId_93_93doHrMYdqh9Meta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/categories/list/[id]/[subId]/[[secondaryId]].vue").then(m => m.default || m)
  },
  {
    name: indexpfTZWeLv3nMeta?.name ?? "funds-categories-list-id___en",
    path: indexpfTZWeLv3nMeta?.path ?? "list/:id()",
    meta: indexpfTZWeLv3nMeta || {},
    alias: indexpfTZWeLv3nMeta?.alias || [],
    redirect: indexpfTZWeLv3nMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/categories/list/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93wcADWhPbSjMeta?.name ?? "funds-details-id___en",
    path: _91id_93wcADWhPbSjMeta?.path ?? "details/:id()",
    meta: _91id_93wcADWhPbSjMeta || {},
    alias: _91id_93wcADWhPbSjMeta?.alias || [],
    redirect: _91id_93wcADWhPbSjMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/details/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPUVZVGHyIjMeta?.name ?? "funds___en",
    path: indexPUVZVGHyIjMeta?.path ?? "",
    meta: indexPUVZVGHyIjMeta || {},
    alias: indexPUVZVGHyIjMeta?.alias || [],
    redirect: indexPUVZVGHyIjMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/index.vue").then(m => m.default || m)
  },
  {
    name: indexZpg6WWboeuMeta?.name ?? "funds-list___en",
    path: indexZpg6WWboeuMeta?.path ?? "list",
    meta: indexZpg6WWboeuMeta || {},
    alias: indexZpg6WWboeuMeta?.alias || [],
    redirect: indexZpg6WWboeuMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/list/index.vue").then(m => m.default || m)
  },
  {
    name: manager6mT3ksJ8OwMeta?.name ?? "funds-manager___en",
    path: manager6mT3ksJ8OwMeta?.path ?? "manager",
    meta: manager6mT3ksJ8OwMeta || {},
    alias: manager6mT3ksJ8OwMeta?.alias || [],
    redirect: manager6mT3ksJ8OwMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/manager.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91id_93_93GQCyBsRXOLMeta?.name ?? "funds-manager-id___en",
    path: _91_91id_93_93GQCyBsRXOLMeta?.path ?? ":id?",
    meta: _91_91id_93_93GQCyBsRXOLMeta || {},
    alias: _91_91id_93_93GQCyBsRXOLMeta?.alias || [],
    redirect: _91_91id_93_93GQCyBsRXOLMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/manager/[[id]].vue").then(m => m.default || m),
    children: [
  {
    name: indexFHfBmuIrrqMeta?.name ?? "funds-manager-id-list___en",
    path: indexFHfBmuIrrqMeta?.path ?? "list",
    meta: indexFHfBmuIrrqMeta || {},
    alias: indexFHfBmuIrrqMeta?.alias || [],
    redirect: indexFHfBmuIrrqMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/manager/[[id]]/list/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexKaP2VkdS0RMeta?.name ?? "funds-search___en",
    path: indexKaP2VkdS0RMeta?.path ?? "search",
    meta: indexKaP2VkdS0RMeta || {},
    alias: indexKaP2VkdS0RMeta?.alias || [],
    redirect: indexKaP2VkdS0RMeta?.redirect,
    component: () => import("/usr/src/packages/funds/pages/funds/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexqksRg9oJ1WMeta?.name ?? "index___he",
    path: indexqksRg9oJ1WMeta?.path ?? "/he",
    meta: indexqksRg9oJ1WMeta || {},
    alias: indexqksRg9oJ1WMeta?.alias || [],
    redirect: indexqksRg9oJ1WMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexqksRg9oJ1WMeta?.name ?? "index___en",
    path: indexqksRg9oJ1WMeta?.path ?? "/en",
    meta: indexqksRg9oJ1WMeta || {},
    alias: indexqksRg9oJ1WMeta?.alias || [],
    redirect: indexqksRg9oJ1WMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93Zgq61ZsU9DMeta?.name ?? "kyc-type___he",
    path: _91type_93Zgq61ZsU9DMeta?.path ?? "/he/kyc/:type()",
    meta: _91type_93Zgq61ZsU9DMeta || {},
    alias: _91type_93Zgq61ZsU9DMeta?.alias || [],
    redirect: _91type_93Zgq61ZsU9DMeta?.redirect,
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type].vue").then(m => m.default || m),
    children: [
  {
    name: _91_91group_93_934w1d1wdPGbMeta?.name ?? "kyc-type-group___he",
    path: _91_91group_93_934w1d1wdPGbMeta?.path ?? ":group?",
    meta: _91_91group_93_934w1d1wdPGbMeta || {},
    alias: _91_91group_93_934w1d1wdPGbMeta?.alias || [],
    redirect: _91_91group_93_934w1d1wdPGbMeta?.redirect,
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type]/[[group]].vue").then(m => m.default || m)
  },
  {
    name: stepsHDNOePwTEnMeta?.name ?? "kyc-type-steps___he",
    path: stepsHDNOePwTEnMeta?.path ?? "steps",
    meta: stepsHDNOePwTEnMeta || {},
    alias: stepsHDNOePwTEnMeta?.alias || [],
    redirect: stepsHDNOePwTEnMeta?.redirect,
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type]/steps.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91type_93Zgq61ZsU9DMeta?.name ?? "kyc-type___en",
    path: _91type_93Zgq61ZsU9DMeta?.path ?? "/en/kyc/:type()",
    meta: _91type_93Zgq61ZsU9DMeta || {},
    alias: _91type_93Zgq61ZsU9DMeta?.alias || [],
    redirect: _91type_93Zgq61ZsU9DMeta?.redirect,
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type].vue").then(m => m.default || m),
    children: [
  {
    name: _91_91group_93_934w1d1wdPGbMeta?.name ?? "kyc-type-group___en",
    path: _91_91group_93_934w1d1wdPGbMeta?.path ?? ":group?",
    meta: _91_91group_93_934w1d1wdPGbMeta || {},
    alias: _91_91group_93_934w1d1wdPGbMeta?.alias || [],
    redirect: _91_91group_93_934w1d1wdPGbMeta?.redirect,
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type]/[[group]].vue").then(m => m.default || m)
  },
  {
    name: stepsHDNOePwTEnMeta?.name ?? "kyc-type-steps___en",
    path: stepsHDNOePwTEnMeta?.path ?? "steps",
    meta: stepsHDNOePwTEnMeta || {},
    alias: stepsHDNOePwTEnMeta?.alias || [],
    redirect: stepsHDNOePwTEnMeta?.redirect,
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type]/steps.vue").then(m => m.default || m)
  }
]
  },
  {
    name: donePxbdkf3XIxMeta?.name ?? "kyc-done___he",
    path: donePxbdkf3XIxMeta?.path ?? "/he/kyc/done",
    meta: donePxbdkf3XIxMeta || {},
    alias: donePxbdkf3XIxMeta?.alias || [],
    redirect: donePxbdkf3XIxMeta?.redirect,
    component: () => import("/usr/src/packages/kyc/pages/kyc/done.vue").then(m => m.default || m)
  },
  {
    name: donePxbdkf3XIxMeta?.name ?? "kyc-done___en",
    path: donePxbdkf3XIxMeta?.path ?? "/en/kyc/done",
    meta: donePxbdkf3XIxMeta || {},
    alias: donePxbdkf3XIxMeta?.alias || [],
    redirect: donePxbdkf3XIxMeta?.redirect,
    component: () => import("/usr/src/packages/kyc/pages/kyc/done.vue").then(m => m.default || m)
  },
  {
    name: indexlHo5lbSUxmMeta?.name ?? "kyc___he",
    path: indexlHo5lbSUxmMeta?.path ?? "/he/kyc",
    meta: indexlHo5lbSUxmMeta || {},
    alias: indexlHo5lbSUxmMeta?.alias || [],
    redirect: indexlHo5lbSUxmMeta?.redirect,
    component: () => import("/usr/src/packages/kyc/pages/kyc/index.vue").then(m => m.default || m)
  },
  {
    name: indexlHo5lbSUxmMeta?.name ?? "kyc___en",
    path: indexlHo5lbSUxmMeta?.path ?? "/en/kyc",
    meta: indexlHo5lbSUxmMeta || {},
    alias: indexlHo5lbSUxmMeta?.alias || [],
    redirect: indexlHo5lbSUxmMeta?.redirect,
    component: () => import("/usr/src/packages/kyc/pages/kyc/index.vue").then(m => m.default || m)
  },
  {
    name: loginYIEPQC6mhGMeta?.name ?? "login___he",
    path: loginYIEPQC6mhGMeta?.path ?? "/he/login",
    meta: loginYIEPQC6mhGMeta || {},
    alias: loginYIEPQC6mhGMeta?.alias || [],
    redirect: loginYIEPQC6mhGMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginYIEPQC6mhGMeta?.name ?? "login___en",
    path: loginYIEPQC6mhGMeta?.path ?? "/en/login",
    meta: loginYIEPQC6mhGMeta || {},
    alias: loginYIEPQC6mhGMeta?.alias || [],
    redirect: loginYIEPQC6mhGMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: partnersnKP1uP9IicMeta?.name ?? "partners___he",
    path: partnersnKP1uP9IicMeta?.path ?? "/he/partners",
    meta: partnersnKP1uP9IicMeta || {},
    alias: partnersnKP1uP9IicMeta?.alias || [],
    redirect: partnersnKP1uP9IicMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersnKP1uP9IicMeta?.name ?? "partners___en",
    path: partnersnKP1uP9IicMeta?.path ?? "/en/partners",
    meta: partnersnKP1uP9IicMeta || {},
    alias: partnersnKP1uP9IicMeta?.alias || [],
    redirect: partnersnKP1uP9IicMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: passwordresetawaitFAii2hbFCfMeta?.name ?? "passwordresetawait___he",
    path: passwordresetawaitFAii2hbFCfMeta?.path ?? "/he/passwordresetawait",
    meta: passwordresetawaitFAii2hbFCfMeta || {},
    alias: passwordresetawaitFAii2hbFCfMeta?.alias || [],
    redirect: passwordresetawaitFAii2hbFCfMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/passwordresetawait.vue").then(m => m.default || m)
  },
  {
    name: passwordresetawaitFAii2hbFCfMeta?.name ?? "passwordresetawait___en",
    path: passwordresetawaitFAii2hbFCfMeta?.path ?? "/en/passwordresetawait",
    meta: passwordresetawaitFAii2hbFCfMeta || {},
    alias: passwordresetawaitFAii2hbFCfMeta?.alias || [],
    redirect: passwordresetawaitFAii2hbFCfMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/passwordresetawait.vue").then(m => m.default || m)
  },
  {
    name: depotaPbp8tJMbrMeta?.name ?? undefined,
    path: depotaPbp8tJMbrMeta?.path ?? "/he/pension/depot",
    meta: depotaPbp8tJMbrMeta || {},
    alias: depotaPbp8tJMbrMeta?.alias || [],
    redirect: depotaPbp8tJMbrMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot.vue").then(m => m.default || m),
    children: [
  {
    name: _91company_93iWeaW7O18lMeta?.name ?? "pension-depot-finqfull-companies-company___he",
    path: _91company_93iWeaW7O18lMeta?.path ?? "finqfull/companies/:company()",
    meta: _91company_93iWeaW7O18lMeta || {},
    alias: _91company_93iWeaW7O18lMeta?.alias || [],
    redirect: _91company_93iWeaW7O18lMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/companies/[company].vue").then(m => m.default || m)
  },
  {
    name: index7V9IkVqMQaMeta?.name ?? "pension-depot-finqfull-companies___he",
    path: index7V9IkVqMQaMeta?.path ?? "finqfull/companies",
    meta: index7V9IkVqMQaMeta || {},
    alias: index7V9IkVqMQaMeta?.alias || [],
    redirect: index7V9IkVqMQaMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexvOJnJJHuf3Meta?.name ?? "pension-depot-finqfull___he",
    path: indexvOJnJJHuf3Meta?.path ?? "finqfull",
    meta: indexvOJnJJHuf3Meta || {},
    alias: indexvOJnJJHuf3Meta?.alias || [],
    redirect: indexvOJnJJHuf3Meta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/index.vue").then(m => m.default || m)
  },
  {
    name: _91specialty_93EMJ8NGR8qiMeta?.name ?? "pension-depot-finqfull-specialization-specialty___he",
    path: _91specialty_93EMJ8NGR8qiMeta?.path ?? "finqfull/specialization/:specialty()",
    meta: _91specialty_93EMJ8NGR8qiMeta || {},
    alias: _91specialty_93EMJ8NGR8qiMeta?.alias || [],
    redirect: _91specialty_93EMJ8NGR8qiMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/[specialty].vue").then(m => m.default || m)
  },
  {
    name: index5EOUCdyNX8Meta?.name ?? "pension-depot-finqfull-specialization___he",
    path: index5EOUCdyNX8Meta?.path ?? "finqfull/specialization",
    meta: index5EOUCdyNX8Meta || {},
    alias: index5EOUCdyNX8Meta?.alias || [],
    redirect: index5EOUCdyNX8Meta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/index.vue").then(m => m.default || m)
  },
  {
    name: _91fundHash_93EFQ29CzgCKMeta?.name ?? "pension-depot-finqfull-watch-fundHash___he",
    path: _91fundHash_93EFQ29CzgCKMeta?.path ?? "finqfull/watch/:fundHash()",
    meta: _91fundHash_93EFQ29CzgCKMeta || {},
    alias: _91fundHash_93EFQ29CzgCKMeta?.alias || [],
    redirect: _91fundHash_93EFQ29CzgCKMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/watch/[fundHash].vue").then(m => m.default || m)
  },
  {
    name: indexiSIKEil1ZxMeta?.name ?? "pension-depot-finqfull-watch___he",
    path: indexiSIKEil1ZxMeta?.path ?? "finqfull/watch",
    meta: indexiSIKEil1ZxMeta || {},
    alias: indexiSIKEil1ZxMeta?.alias || [],
    redirect: indexiSIKEil1ZxMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/watch/index.vue").then(m => m.default || m)
  },
  {
    name: indexUOvEfDpeO0Meta?.name ?? "pension-depot___he",
    path: indexUOvEfDpeO0Meta?.path ?? "",
    meta: indexUOvEfDpeO0Meta || {},
    alias: indexUOvEfDpeO0Meta?.alias || [],
    redirect: indexUOvEfDpeO0Meta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/index.vue").then(m => m.default || m)
  },
  {
    name: companiesOMujoLmf23Meta?.name ?? "pension-depot-leading-funds-companies___he",
    path: companiesOMujoLmf23Meta?.path ?? "leading-funds/companies",
    meta: companiesOMujoLmf23Meta || {},
    alias: companiesOMujoLmf23Meta?.alias || [],
    redirect: companiesOMujoLmf23Meta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/leading-funds/companies.vue").then(m => m.default || m)
  },
  {
    name: indexufVtMGX5vzMeta?.name ?? "pension-depot-leading-funds___he",
    path: indexufVtMGX5vzMeta?.path ?? "leading-funds",
    meta: indexufVtMGX5vzMeta || {},
    alias: indexufVtMGX5vzMeta?.alias || [],
    redirect: indexufVtMGX5vzMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/leading-funds/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: depotaPbp8tJMbrMeta?.name ?? undefined,
    path: depotaPbp8tJMbrMeta?.path ?? "/en/pension/depot",
    meta: depotaPbp8tJMbrMeta || {},
    alias: depotaPbp8tJMbrMeta?.alias || [],
    redirect: depotaPbp8tJMbrMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot.vue").then(m => m.default || m),
    children: [
  {
    name: _91company_93iWeaW7O18lMeta?.name ?? "pension-depot-finqfull-companies-company___en",
    path: _91company_93iWeaW7O18lMeta?.path ?? "finqfull/companies/:company()",
    meta: _91company_93iWeaW7O18lMeta || {},
    alias: _91company_93iWeaW7O18lMeta?.alias || [],
    redirect: _91company_93iWeaW7O18lMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/companies/[company].vue").then(m => m.default || m)
  },
  {
    name: index7V9IkVqMQaMeta?.name ?? "pension-depot-finqfull-companies___en",
    path: index7V9IkVqMQaMeta?.path ?? "finqfull/companies",
    meta: index7V9IkVqMQaMeta || {},
    alias: index7V9IkVqMQaMeta?.alias || [],
    redirect: index7V9IkVqMQaMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexvOJnJJHuf3Meta?.name ?? "pension-depot-finqfull___en",
    path: indexvOJnJJHuf3Meta?.path ?? "finqfull",
    meta: indexvOJnJJHuf3Meta || {},
    alias: indexvOJnJJHuf3Meta?.alias || [],
    redirect: indexvOJnJJHuf3Meta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/index.vue").then(m => m.default || m)
  },
  {
    name: _91specialty_93EMJ8NGR8qiMeta?.name ?? "pension-depot-finqfull-specialization-specialty___en",
    path: _91specialty_93EMJ8NGR8qiMeta?.path ?? "finqfull/specialization/:specialty()",
    meta: _91specialty_93EMJ8NGR8qiMeta || {},
    alias: _91specialty_93EMJ8NGR8qiMeta?.alias || [],
    redirect: _91specialty_93EMJ8NGR8qiMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/[specialty].vue").then(m => m.default || m)
  },
  {
    name: index5EOUCdyNX8Meta?.name ?? "pension-depot-finqfull-specialization___en",
    path: index5EOUCdyNX8Meta?.path ?? "finqfull/specialization",
    meta: index5EOUCdyNX8Meta || {},
    alias: index5EOUCdyNX8Meta?.alias || [],
    redirect: index5EOUCdyNX8Meta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/index.vue").then(m => m.default || m)
  },
  {
    name: _91fundHash_93EFQ29CzgCKMeta?.name ?? "pension-depot-finqfull-watch-fundHash___en",
    path: _91fundHash_93EFQ29CzgCKMeta?.path ?? "finqfull/watch/:fundHash()",
    meta: _91fundHash_93EFQ29CzgCKMeta || {},
    alias: _91fundHash_93EFQ29CzgCKMeta?.alias || [],
    redirect: _91fundHash_93EFQ29CzgCKMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/watch/[fundHash].vue").then(m => m.default || m)
  },
  {
    name: indexiSIKEil1ZxMeta?.name ?? "pension-depot-finqfull-watch___en",
    path: indexiSIKEil1ZxMeta?.path ?? "finqfull/watch",
    meta: indexiSIKEil1ZxMeta || {},
    alias: indexiSIKEil1ZxMeta?.alias || [],
    redirect: indexiSIKEil1ZxMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/watch/index.vue").then(m => m.default || m)
  },
  {
    name: indexUOvEfDpeO0Meta?.name ?? "pension-depot___en",
    path: indexUOvEfDpeO0Meta?.path ?? "",
    meta: indexUOvEfDpeO0Meta || {},
    alias: indexUOvEfDpeO0Meta?.alias || [],
    redirect: indexUOvEfDpeO0Meta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/index.vue").then(m => m.default || m)
  },
  {
    name: companiesOMujoLmf23Meta?.name ?? "pension-depot-leading-funds-companies___en",
    path: companiesOMujoLmf23Meta?.path ?? "leading-funds/companies",
    meta: companiesOMujoLmf23Meta || {},
    alias: companiesOMujoLmf23Meta?.alias || [],
    redirect: companiesOMujoLmf23Meta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/leading-funds/companies.vue").then(m => m.default || m)
  },
  {
    name: indexufVtMGX5vzMeta?.name ?? "pension-depot-leading-funds___en",
    path: indexufVtMGX5vzMeta?.path ?? "leading-funds",
    meta: indexufVtMGX5vzMeta || {},
    alias: indexufVtMGX5vzMeta?.alias || [],
    redirect: indexufVtMGX5vzMeta?.redirect,
    component: () => import("/usr/src/packages/pension/pages/pension/depot/leading-funds/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: portfoliosjxRGDJlRxLMeta?.name ?? "portfolios___he",
    path: portfoliosjxRGDJlRxLMeta?.path ?? "/he/portfolios",
    meta: portfoliosjxRGDJlRxLMeta || {},
    alias: portfoliosjxRGDJlRxLMeta?.alias || [],
    redirect: "/",
    component: () => import("/usr/src/packages/portfolios/pages/portfolios.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91pid_93_93FAQHdTbWrNMeta?.name ?? "portfolios-consult-details-pid___he",
    path: _91_91pid_93_93FAQHdTbWrNMeta?.path ?? "consult/details/:pid?",
    meta: _91_91pid_93_93FAQHdTbWrNMeta || {},
    alias: _91_91pid_93_93FAQHdTbWrNMeta?.alias || [],
    redirect: _91_91pid_93_93FAQHdTbWrNMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]].vue").then(m => m.default || m),
    children: [
  {
    name: _91_91fid_93_93B3CL5Ky413Meta?.name ?? "portfolios-consult-details-pid-fid___he",
    path: _91_91fid_93_93B3CL5Ky413Meta?.path ?? ":fid?",
    meta: _91_91fid_93_93B3CL5Ky413Meta || {},
    alias: _91_91fid_93_93B3CL5Ky413Meta?.alias || [],
    redirect: _91_91fid_93_93B3CL5Ky413Meta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]]/[[fid]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexCuvpEcNESTMeta?.name ?? "portfolios-favorites___he",
    path: indexCuvpEcNESTMeta?.path ?? "favorites",
    meta: indexCuvpEcNESTMeta || {},
    alias: indexCuvpEcNESTMeta?.alias || [],
    redirect: indexCuvpEcNESTMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: lobbyVVzKDVXQ0XMeta?.name ?? "portfolios-lobby___he",
    path: lobbyVVzKDVXQ0XMeta?.path ?? "lobby",
    meta: lobbyVVzKDVXQ0XMeta || {},
    alias: lobbyVVzKDVXQ0XMeta?.alias || [],
    redirect: lobbyVVzKDVXQ0XMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/lobby.vue").then(m => m.default || m)
  },
  {
    name: storenavOVDyujfMeta?.name ?? undefined,
    path: storenavOVDyujfMeta?.path ?? "store",
    meta: storenavOVDyujfMeta || {},
    alias: storenavOVDyujfMeta?.alias || [],
    redirect: storenavOVDyujfMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91pid_93_935JUUTaXGU6Meta?.name ?? "portfolios-store-details-pid___he",
    path: _91_91pid_93_935JUUTaXGU6Meta?.path ?? "details/:pid?",
    meta: _91_91pid_93_935JUUTaXGU6Meta || {},
    alias: _91_91pid_93_935JUUTaXGU6Meta?.alias || [],
    redirect: _91_91pid_93_935JUUTaXGU6Meta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]].vue").then(m => m.default || m),
    children: [
  {
    name: _91_91fid_93_93olluoChfBKMeta?.name ?? "portfolios-store-details-pid-fid___he",
    path: _91_91fid_93_93olluoChfBKMeta?.path ?? ":fid?",
    meta: _91_91fid_93_93olluoChfBKMeta || {},
    alias: _91_91fid_93_93olluoChfBKMeta?.alias || [],
    redirect: _91_91fid_93_93olluoChfBKMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]]/[[fid]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexetdbuDprAYMeta?.name ?? "portfolios-store___he",
    path: indexetdbuDprAYMeta?.path ?? "",
    meta: indexetdbuDprAYMeta || {},
    alias: indexetdbuDprAYMeta?.alias || [],
    redirect: indexetdbuDprAYMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: portfoliosjxRGDJlRxLMeta?.name ?? "portfolios___en",
    path: portfoliosjxRGDJlRxLMeta?.path ?? "/en/portfolios",
    meta: portfoliosjxRGDJlRxLMeta || {},
    alias: portfoliosjxRGDJlRxLMeta?.alias || [],
    redirect: "/",
    component: () => import("/usr/src/packages/portfolios/pages/portfolios.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91pid_93_93FAQHdTbWrNMeta?.name ?? "portfolios-consult-details-pid___en",
    path: _91_91pid_93_93FAQHdTbWrNMeta?.path ?? "consult/details/:pid?",
    meta: _91_91pid_93_93FAQHdTbWrNMeta || {},
    alias: _91_91pid_93_93FAQHdTbWrNMeta?.alias || [],
    redirect: _91_91pid_93_93FAQHdTbWrNMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]].vue").then(m => m.default || m),
    children: [
  {
    name: _91_91fid_93_93B3CL5Ky413Meta?.name ?? "portfolios-consult-details-pid-fid___en",
    path: _91_91fid_93_93B3CL5Ky413Meta?.path ?? ":fid?",
    meta: _91_91fid_93_93B3CL5Ky413Meta || {},
    alias: _91_91fid_93_93B3CL5Ky413Meta?.alias || [],
    redirect: _91_91fid_93_93B3CL5Ky413Meta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]]/[[fid]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexCuvpEcNESTMeta?.name ?? "portfolios-favorites___en",
    path: indexCuvpEcNESTMeta?.path ?? "favorites",
    meta: indexCuvpEcNESTMeta || {},
    alias: indexCuvpEcNESTMeta?.alias || [],
    redirect: indexCuvpEcNESTMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: lobbyVVzKDVXQ0XMeta?.name ?? "portfolios-lobby___en",
    path: lobbyVVzKDVXQ0XMeta?.path ?? "lobby",
    meta: lobbyVVzKDVXQ0XMeta || {},
    alias: lobbyVVzKDVXQ0XMeta?.alias || [],
    redirect: lobbyVVzKDVXQ0XMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/lobby.vue").then(m => m.default || m)
  },
  {
    name: storenavOVDyujfMeta?.name ?? undefined,
    path: storenavOVDyujfMeta?.path ?? "store",
    meta: storenavOVDyujfMeta || {},
    alias: storenavOVDyujfMeta?.alias || [],
    redirect: storenavOVDyujfMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91pid_93_935JUUTaXGU6Meta?.name ?? "portfolios-store-details-pid___en",
    path: _91_91pid_93_935JUUTaXGU6Meta?.path ?? "details/:pid?",
    meta: _91_91pid_93_935JUUTaXGU6Meta || {},
    alias: _91_91pid_93_935JUUTaXGU6Meta?.alias || [],
    redirect: _91_91pid_93_935JUUTaXGU6Meta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]].vue").then(m => m.default || m),
    children: [
  {
    name: _91_91fid_93_93olluoChfBKMeta?.name ?? "portfolios-store-details-pid-fid___en",
    path: _91_91fid_93_93olluoChfBKMeta?.path ?? ":fid?",
    meta: _91_91fid_93_93olluoChfBKMeta || {},
    alias: _91_91fid_93_93olluoChfBKMeta?.alias || [],
    redirect: _91_91fid_93_93olluoChfBKMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]]/[[fid]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexetdbuDprAYMeta?.name ?? "portfolios-store___en",
    path: indexetdbuDprAYMeta?.path ?? "",
    meta: indexetdbuDprAYMeta || {},
    alias: indexetdbuDprAYMeta?.alias || [],
    redirect: indexetdbuDprAYMeta?.redirect,
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: pricingOIBELzIw3pMeta?.name ?? "pricing___he",
    path: pricingOIBELzIw3pMeta?.path ?? "/he/pricing",
    meta: pricingOIBELzIw3pMeta || {},
    alias: pricingOIBELzIw3pMeta?.alias || [],
    redirect: pricingOIBELzIw3pMeta?.redirect,
    component: () => import("/usr/src/packages/pricing/pages/pricing.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91productId_93_93hqPFitOqkYMeta?.name ?? "pricing-productId___he",
    path: _91_91productId_93_93hqPFitOqkYMeta?.path ?? ":productId?",
    meta: _91_91productId_93_93hqPFitOqkYMeta || {},
    alias: _91_91productId_93_93hqPFitOqkYMeta?.alias || [],
    redirect: _91_91productId_93_93hqPFitOqkYMeta?.redirect,
    component: () => import("/usr/src/packages/pricing/pages/pricing/[[productId]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: pricingOIBELzIw3pMeta?.name ?? "pricing___en",
    path: pricingOIBELzIw3pMeta?.path ?? "/en/pricing",
    meta: pricingOIBELzIw3pMeta || {},
    alias: pricingOIBELzIw3pMeta?.alias || [],
    redirect: pricingOIBELzIw3pMeta?.redirect,
    component: () => import("/usr/src/packages/pricing/pages/pricing.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91productId_93_93hqPFitOqkYMeta?.name ?? "pricing-productId___en",
    path: _91_91productId_93_93hqPFitOqkYMeta?.path ?? ":productId?",
    meta: _91_91productId_93_93hqPFitOqkYMeta || {},
    alias: _91_91productId_93_93hqPFitOqkYMeta?.alias || [],
    redirect: _91_91productId_93_93hqPFitOqkYMeta?.redirect,
    component: () => import("/usr/src/packages/pricing/pages/pricing/[[productId]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: privacyW7FWgOcv9fMeta?.name ?? "privacy___he",
    path: privacyW7FWgOcv9fMeta?.path ?? "/he/privacy",
    meta: privacyW7FWgOcv9fMeta || {},
    alias: privacyW7FWgOcv9fMeta?.alias || [],
    redirect: privacyW7FWgOcv9fMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyW7FWgOcv9fMeta?.name ?? "privacy___en",
    path: privacyW7FWgOcv9fMeta?.path ?? "/en/privacy",
    meta: privacyW7FWgOcv9fMeta || {},
    alias: privacyW7FWgOcv9fMeta?.alias || [],
    redirect: privacyW7FWgOcv9fMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: profilezv8G5W5pJQMeta?.name ?? "profile___he",
    path: profilezv8G5W5pJQMeta?.path ?? "/he/profile",
    meta: profilezv8G5W5pJQMeta || {},
    alias: profilezv8G5W5pJQMeta?.alias || [],
    redirect: profilezv8G5W5pJQMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: infoFL8Bz5DpZJMeta?.name ?? "profile-info___he",
    path: infoFL8Bz5DpZJMeta?.path ?? "info",
    meta: infoFL8Bz5DpZJMeta || {},
    alias: infoFL8Bz5DpZJMeta?.alias || [],
    redirect: infoFL8Bz5DpZJMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: investmentsKaKYZu24t3Meta?.name ?? undefined,
    path: investmentsKaKYZu24t3Meta?.path ?? "investments",
    meta: investmentsKaKYZu24t3Meta || {},
    alias: investmentsKaKYZu24t3Meta?.alias || [],
    redirect: investmentsKaKYZu24t3Meta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91id_93_938xxf54ZtMqMeta?.name ?? "profile-investments-funds-id___he",
    path: _91_91id_93_938xxf54ZtMqMeta?.path ?? "funds/:id?",
    meta: _91_91id_93_938xxf54ZtMqMeta || {},
    alias: _91_91id_93_938xxf54ZtMqMeta?.alias || [],
    redirect: _91_91id_93_938xxf54ZtMqMeta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/funds/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexmc0Fx1pJFqMeta?.name ?? "profile-investments___he",
    path: indexmc0Fx1pJFqMeta?.path ?? "",
    meta: indexmc0Fx1pJFqMeta || {},
    alias: indexmc0Fx1pJFqMeta?.alias || [],
    redirect: indexmc0Fx1pJFqMeta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/index.vue").then(m => m.default || m)
  },
  {
    name: messages7UYmgPe1CQMeta?.name ?? "profile-investments-messages___he",
    path: messages7UYmgPe1CQMeta?.path ?? "messages",
    meta: messages7UYmgPe1CQMeta || {},
    alias: messages7UYmgPe1CQMeta?.alias || [],
    redirect: messages7UYmgPe1CQMeta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/messages.vue").then(m => m.default || m)
  },
  {
    name: reportsQKsxN8v9rlMeta?.name ?? "profile-investments-reports___he",
    path: reportsQKsxN8v9rlMeta?.path ?? "reports",
    meta: reportsQKsxN8v9rlMeta || {},
    alias: reportsQKsxN8v9rlMeta?.alias || [],
    redirect: reportsQKsxN8v9rlMeta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/reports.vue").then(m => m.default || m)
  },
  {
    name: transactionsn9eMzhgE0jMeta?.name ?? "profile-investments-transactions___he",
    path: transactionsn9eMzhgE0jMeta?.path ?? "transactions",
    meta: transactionsn9eMzhgE0jMeta || {},
    alias: transactionsn9eMzhgE0jMeta?.alias || [],
    redirect: transactionsn9eMzhgE0jMeta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/transactions.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: profilezv8G5W5pJQMeta?.name ?? "profile___en",
    path: profilezv8G5W5pJQMeta?.path ?? "/en/profile",
    meta: profilezv8G5W5pJQMeta || {},
    alias: profilezv8G5W5pJQMeta?.alias || [],
    redirect: profilezv8G5W5pJQMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: infoFL8Bz5DpZJMeta?.name ?? "profile-info___en",
    path: infoFL8Bz5DpZJMeta?.path ?? "info",
    meta: infoFL8Bz5DpZJMeta || {},
    alias: infoFL8Bz5DpZJMeta?.alias || [],
    redirect: infoFL8Bz5DpZJMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: investmentsKaKYZu24t3Meta?.name ?? undefined,
    path: investmentsKaKYZu24t3Meta?.path ?? "investments",
    meta: investmentsKaKYZu24t3Meta || {},
    alias: investmentsKaKYZu24t3Meta?.alias || [],
    redirect: investmentsKaKYZu24t3Meta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments.vue").then(m => m.default || m),
    children: [
  {
    name: _91_91id_93_938xxf54ZtMqMeta?.name ?? "profile-investments-funds-id___en",
    path: _91_91id_93_938xxf54ZtMqMeta?.path ?? "funds/:id?",
    meta: _91_91id_93_938xxf54ZtMqMeta || {},
    alias: _91_91id_93_938xxf54ZtMqMeta?.alias || [],
    redirect: _91_91id_93_938xxf54ZtMqMeta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/funds/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexmc0Fx1pJFqMeta?.name ?? "profile-investments___en",
    path: indexmc0Fx1pJFqMeta?.path ?? "",
    meta: indexmc0Fx1pJFqMeta || {},
    alias: indexmc0Fx1pJFqMeta?.alias || [],
    redirect: indexmc0Fx1pJFqMeta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/index.vue").then(m => m.default || m)
  },
  {
    name: messages7UYmgPe1CQMeta?.name ?? "profile-investments-messages___en",
    path: messages7UYmgPe1CQMeta?.path ?? "messages",
    meta: messages7UYmgPe1CQMeta || {},
    alias: messages7UYmgPe1CQMeta?.alias || [],
    redirect: messages7UYmgPe1CQMeta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/messages.vue").then(m => m.default || m)
  },
  {
    name: reportsQKsxN8v9rlMeta?.name ?? "profile-investments-reports___en",
    path: reportsQKsxN8v9rlMeta?.path ?? "reports",
    meta: reportsQKsxN8v9rlMeta || {},
    alias: reportsQKsxN8v9rlMeta?.alias || [],
    redirect: reportsQKsxN8v9rlMeta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/reports.vue").then(m => m.default || m)
  },
  {
    name: transactionsn9eMzhgE0jMeta?.name ?? "profile-investments-transactions___en",
    path: transactionsn9eMzhgE0jMeta?.path ?? "transactions",
    meta: transactionsn9eMzhgE0jMeta || {},
    alias: transactionsn9eMzhgE0jMeta?.alias || [],
    redirect: transactionsn9eMzhgE0jMeta?.redirect,
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/transactions.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: signupD0zLV4v9noMeta?.name ?? "signup___he",
    path: signupD0zLV4v9noMeta?.path ?? "/he/signup",
    meta: signupD0zLV4v9noMeta || {},
    alias: signupD0zLV4v9noMeta?.alias || [],
    redirect: signupD0zLV4v9noMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: signupD0zLV4v9noMeta?.name ?? "signup___en",
    path: signupD0zLV4v9noMeta?.path ?? "/en/signup",
    meta: signupD0zLV4v9noMeta || {},
    alias: signupD0zLV4v9noMeta?.alias || [],
    redirect: signupD0zLV4v9noMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: stocksYNZBSUl7vEMeta?.name ?? undefined,
    path: stocksYNZBSUl7vEMeta?.path ?? "/he/stocks",
    meta: stocksYNZBSUl7vEMeta || {},
    alias: stocksYNZBSUl7vEMeta?.alias || [],
    redirect: stocksYNZBSUl7vEMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks.vue").then(m => m.default || m),
    children: [
  {
    name: indexmzXPjhq5EgMeta?.name ?? "stocks___he",
    path: indexmzXPjhq5EgMeta?.path ?? "",
    meta: indexmzXPjhq5EgMeta || {},
    alias: indexmzXPjhq5EgMeta?.alias || [],
    redirect: indexmzXPjhq5EgMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/index.vue").then(m => m.default || m)
  },
  {
    name: indexlmLBSz9fOYMeta?.name ?? "stocks-momentum___he",
    path: indexlmLBSz9fOYMeta?.path ?? "momentum",
    meta: indexlmLBSz9fOYMeta || {},
    alias: indexlmLBSz9fOYMeta?.alias || [],
    redirect: indexlmLBSz9fOYMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/momentum/index.vue").then(m => m.default || m)
  },
  {
    name: negative3xD1YXaAWuMeta?.name ?? "stocks-momentum-negative___he",
    path: negative3xD1YXaAWuMeta?.path ?? "momentum/negative",
    meta: negative3xD1YXaAWuMeta || {},
    alias: negative3xD1YXaAWuMeta?.alias || [],
    redirect: negative3xD1YXaAWuMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/momentum/negative.vue").then(m => m.default || m)
  },
  {
    name: positivecfmu6LyIYtMeta?.name ?? "stocks-momentum-positive___he",
    path: positivecfmu6LyIYtMeta?.path ?? "momentum/positive",
    meta: positivecfmu6LyIYtMeta || {},
    alias: positivecfmu6LyIYtMeta?.alias || [],
    redirect: positivecfmu6LyIYtMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/momentum/positive.vue").then(m => m.default || m)
  },
  {
    name: portfolioszi0VENqbE6Meta?.name ?? undefined,
    path: portfolioszi0VENqbE6Meta?.path ?? "portfolios",
    meta: portfolioszi0VENqbE6Meta || {},
    alias: portfolioszi0VENqbE6Meta?.alias || [],
    redirect: portfolioszi0VENqbE6Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios.vue").then(m => m.default || m),
    children: [
  {
    name: _91portfolioType_93Lz5OTVGOUJMeta?.name ?? undefined,
    path: _91portfolioType_93Lz5OTVGOUJMeta?.path ?? ":portfolioType()",
    meta: _91portfolioType_93Lz5OTVGOUJMeta || {},
    alias: _91portfolioType_93Lz5OTVGOUJMeta?.alias || [],
    redirect: _91portfolioType_93Lz5OTVGOUJMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType].vue").then(m => m.default || m),
    children: [
  {
    name: indexPDJrGGTxBuMeta?.name ?? "stocks-portfolios-portfolioType___he",
    path: indexPDJrGGTxBuMeta?.path ?? "",
    meta: indexPDJrGGTxBuMeta || {},
    alias: indexPDJrGGTxBuMeta?.alias || [],
    redirect: indexPDJrGGTxBuMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/index.vue").then(m => m.default || m)
  },
  {
    name: returnsnxcTGiYrnyMeta?.name ?? "stocks-portfolios-portfolioType-returns___he",
    path: returnsnxcTGiYrnyMeta?.path ?? "returns",
    meta: returnsnxcTGiYrnyMeta || {},
    alias: returnsnxcTGiYrnyMeta?.alias || [],
    redirect: returnsnxcTGiYrnyMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/returns.vue").then(m => m.default || m)
  },
  {
    name: transactionslgjJ1jRxX6Meta?.name ?? "stocks-portfolios-portfolioType-transactions___he",
    path: transactionslgjJ1jRxX6Meta?.path ?? "transactions",
    meta: transactionslgjJ1jRxX6Meta || {},
    alias: transactionslgjJ1jRxX6Meta?.alias || [],
    redirect: transactionslgjJ1jRxX6Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: detailsxa2DsXpMPrMeta?.name ?? "stocks-portfolios-details___he",
    path: detailsxa2DsXpMPrMeta?.path ?? "details",
    meta: detailsxa2DsXpMPrMeta || {},
    alias: detailsxa2DsXpMPrMeta?.alias || [],
    redirect: detailsxa2DsXpMPrMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93xL6fM6Wr70Meta?.name ?? "stocks-portfolios-details-id___he",
    path: _91id_93xL6fM6Wr70Meta?.path ?? ":id()",
    meta: _91id_93xL6fM6Wr70Meta || {},
    alias: _91id_93xL6fM6Wr70Meta?.alias || [],
    redirect: _91id_93xL6fM6Wr70Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexsNto0OwKRYMeta?.name ?? "stocks-portfolios___he",
    path: indexsNto0OwKRYMeta?.path ?? "",
    meta: indexsNto0OwKRYMeta || {},
    alias: indexsNto0OwKRYMeta?.alias || [],
    redirect: indexsNto0OwKRYMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/index.vue").then(m => m.default || m)
  },
  {
    name: snp500mPrgrMS26sMeta?.name ?? undefined,
    path: snp500mPrgrMS26sMeta?.path ?? "snp500",
    meta: snp500mPrgrMS26sMeta || {},
    alias: snp500mPrgrMS26sMeta?.alias || [],
    redirect: snp500mPrgrMS26sMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500.vue").then(m => m.default || m),
    children: [
  {
    name: indexRIwpw4c1lXMeta?.name ?? "stocks-portfolios-snp500___he",
    path: indexRIwpw4c1lXMeta?.path ?? "",
    meta: indexRIwpw4c1lXMeta || {},
    alias: indexRIwpw4c1lXMeta?.alias || [],
    redirect: indexRIwpw4c1lXMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/index.vue").then(m => m.default || m)
  },
  {
    name: sectors74tcQjPqt4Meta?.name ?? undefined,
    path: sectors74tcQjPqt4Meta?.path ?? "sectors",
    meta: sectors74tcQjPqt4Meta || {},
    alias: sectors74tcQjPqt4Meta?.alias || [],
    redirect: sectors74tcQjPqt4Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors.vue").then(m => m.default || m),
    children: [
  {
    name: _91sectorId_934XIx6fVjIbMeta?.name ?? "stocks-portfolios-snp500-sectors-sectorId___he",
    path: _91sectorId_934XIx6fVjIbMeta?.path ?? ":sectorId()",
    meta: _91sectorId_934XIx6fVjIbMeta || {},
    alias: _91sectorId_934XIx6fVjIbMeta?.alias || [],
    redirect: _91sectorId_934XIx6fVjIbMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors/[sectorId].vue").then(m => m.default || m)
  },
  {
    name: indexJ5i4BUbMRaMeta?.name ?? "stocks-portfolios-snp500-sectors___he",
    path: indexJ5i4BUbMRaMeta?.path ?? "",
    meta: indexJ5i4BUbMRaMeta || {},
    alias: indexJ5i4BUbMRaMeta?.alias || [],
    redirect: indexJ5i4BUbMRaMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: searchT1B2Y8KjdfMeta?.name ?? "stocks-search___he",
    path: searchT1B2Y8KjdfMeta?.path ?? "search",
    meta: searchT1B2Y8KjdfMeta || {},
    alias: searchT1B2Y8KjdfMeta?.alias || [],
    redirect: searchT1B2Y8KjdfMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/search.vue").then(m => m.default || m)
  },
  {
    name: top20QVDMEgSsSmMeta?.name ?? "stocks-top20___he",
    path: top20QVDMEgSsSmMeta?.path ?? "top20",
    meta: top20QVDMEgSsSmMeta || {},
    alias: top20QVDMEgSsSmMeta?.alias || [],
    redirect: top20QVDMEgSsSmMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/top20.vue").then(m => m.default || m)
  }
]
  },
  {
    name: stocksYNZBSUl7vEMeta?.name ?? undefined,
    path: stocksYNZBSUl7vEMeta?.path ?? "/en/stocks",
    meta: stocksYNZBSUl7vEMeta || {},
    alias: stocksYNZBSUl7vEMeta?.alias || [],
    redirect: stocksYNZBSUl7vEMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks.vue").then(m => m.default || m),
    children: [
  {
    name: indexmzXPjhq5EgMeta?.name ?? "stocks___en",
    path: indexmzXPjhq5EgMeta?.path ?? "",
    meta: indexmzXPjhq5EgMeta || {},
    alias: indexmzXPjhq5EgMeta?.alias || [],
    redirect: indexmzXPjhq5EgMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/index.vue").then(m => m.default || m)
  },
  {
    name: indexlmLBSz9fOYMeta?.name ?? "stocks-momentum___en",
    path: indexlmLBSz9fOYMeta?.path ?? "momentum",
    meta: indexlmLBSz9fOYMeta || {},
    alias: indexlmLBSz9fOYMeta?.alias || [],
    redirect: indexlmLBSz9fOYMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/momentum/index.vue").then(m => m.default || m)
  },
  {
    name: negative3xD1YXaAWuMeta?.name ?? "stocks-momentum-negative___en",
    path: negative3xD1YXaAWuMeta?.path ?? "momentum/negative",
    meta: negative3xD1YXaAWuMeta || {},
    alias: negative3xD1YXaAWuMeta?.alias || [],
    redirect: negative3xD1YXaAWuMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/momentum/negative.vue").then(m => m.default || m)
  },
  {
    name: positivecfmu6LyIYtMeta?.name ?? "stocks-momentum-positive___en",
    path: positivecfmu6LyIYtMeta?.path ?? "momentum/positive",
    meta: positivecfmu6LyIYtMeta || {},
    alias: positivecfmu6LyIYtMeta?.alias || [],
    redirect: positivecfmu6LyIYtMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/momentum/positive.vue").then(m => m.default || m)
  },
  {
    name: portfolioszi0VENqbE6Meta?.name ?? undefined,
    path: portfolioszi0VENqbE6Meta?.path ?? "portfolios",
    meta: portfolioszi0VENqbE6Meta || {},
    alias: portfolioszi0VENqbE6Meta?.alias || [],
    redirect: portfolioszi0VENqbE6Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios.vue").then(m => m.default || m),
    children: [
  {
    name: _91portfolioType_93Lz5OTVGOUJMeta?.name ?? undefined,
    path: _91portfolioType_93Lz5OTVGOUJMeta?.path ?? ":portfolioType()",
    meta: _91portfolioType_93Lz5OTVGOUJMeta || {},
    alias: _91portfolioType_93Lz5OTVGOUJMeta?.alias || [],
    redirect: _91portfolioType_93Lz5OTVGOUJMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType].vue").then(m => m.default || m),
    children: [
  {
    name: indexPDJrGGTxBuMeta?.name ?? "stocks-portfolios-portfolioType___en",
    path: indexPDJrGGTxBuMeta?.path ?? "",
    meta: indexPDJrGGTxBuMeta || {},
    alias: indexPDJrGGTxBuMeta?.alias || [],
    redirect: indexPDJrGGTxBuMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/index.vue").then(m => m.default || m)
  },
  {
    name: returnsnxcTGiYrnyMeta?.name ?? "stocks-portfolios-portfolioType-returns___en",
    path: returnsnxcTGiYrnyMeta?.path ?? "returns",
    meta: returnsnxcTGiYrnyMeta || {},
    alias: returnsnxcTGiYrnyMeta?.alias || [],
    redirect: returnsnxcTGiYrnyMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/returns.vue").then(m => m.default || m)
  },
  {
    name: transactionslgjJ1jRxX6Meta?.name ?? "stocks-portfolios-portfolioType-transactions___en",
    path: transactionslgjJ1jRxX6Meta?.path ?? "transactions",
    meta: transactionslgjJ1jRxX6Meta || {},
    alias: transactionslgjJ1jRxX6Meta?.alias || [],
    redirect: transactionslgjJ1jRxX6Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/transactions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: detailsxa2DsXpMPrMeta?.name ?? "stocks-portfolios-details___en",
    path: detailsxa2DsXpMPrMeta?.path ?? "details",
    meta: detailsxa2DsXpMPrMeta || {},
    alias: detailsxa2DsXpMPrMeta?.alias || [],
    redirect: detailsxa2DsXpMPrMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93xL6fM6Wr70Meta?.name ?? "stocks-portfolios-details-id___en",
    path: _91id_93xL6fM6Wr70Meta?.path ?? ":id()",
    meta: _91id_93xL6fM6Wr70Meta || {},
    alias: _91id_93xL6fM6Wr70Meta?.alias || [],
    redirect: _91id_93xL6fM6Wr70Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexsNto0OwKRYMeta?.name ?? "stocks-portfolios___en",
    path: indexsNto0OwKRYMeta?.path ?? "",
    meta: indexsNto0OwKRYMeta || {},
    alias: indexsNto0OwKRYMeta?.alias || [],
    redirect: indexsNto0OwKRYMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/index.vue").then(m => m.default || m)
  },
  {
    name: snp500mPrgrMS26sMeta?.name ?? undefined,
    path: snp500mPrgrMS26sMeta?.path ?? "snp500",
    meta: snp500mPrgrMS26sMeta || {},
    alias: snp500mPrgrMS26sMeta?.alias || [],
    redirect: snp500mPrgrMS26sMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500.vue").then(m => m.default || m),
    children: [
  {
    name: indexRIwpw4c1lXMeta?.name ?? "stocks-portfolios-snp500___en",
    path: indexRIwpw4c1lXMeta?.path ?? "",
    meta: indexRIwpw4c1lXMeta || {},
    alias: indexRIwpw4c1lXMeta?.alias || [],
    redirect: indexRIwpw4c1lXMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/index.vue").then(m => m.default || m)
  },
  {
    name: sectors74tcQjPqt4Meta?.name ?? undefined,
    path: sectors74tcQjPqt4Meta?.path ?? "sectors",
    meta: sectors74tcQjPqt4Meta || {},
    alias: sectors74tcQjPqt4Meta?.alias || [],
    redirect: sectors74tcQjPqt4Meta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors.vue").then(m => m.default || m),
    children: [
  {
    name: _91sectorId_934XIx6fVjIbMeta?.name ?? "stocks-portfolios-snp500-sectors-sectorId___en",
    path: _91sectorId_934XIx6fVjIbMeta?.path ?? ":sectorId()",
    meta: _91sectorId_934XIx6fVjIbMeta || {},
    alias: _91sectorId_934XIx6fVjIbMeta?.alias || [],
    redirect: _91sectorId_934XIx6fVjIbMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors/[sectorId].vue").then(m => m.default || m)
  },
  {
    name: indexJ5i4BUbMRaMeta?.name ?? "stocks-portfolios-snp500-sectors___en",
    path: indexJ5i4BUbMRaMeta?.path ?? "",
    meta: indexJ5i4BUbMRaMeta || {},
    alias: indexJ5i4BUbMRaMeta?.alias || [],
    redirect: indexJ5i4BUbMRaMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: searchT1B2Y8KjdfMeta?.name ?? "stocks-search___en",
    path: searchT1B2Y8KjdfMeta?.path ?? "search",
    meta: searchT1B2Y8KjdfMeta || {},
    alias: searchT1B2Y8KjdfMeta?.alias || [],
    redirect: searchT1B2Y8KjdfMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/search.vue").then(m => m.default || m)
  },
  {
    name: top20QVDMEgSsSmMeta?.name ?? "stocks-top20___en",
    path: top20QVDMEgSsSmMeta?.path ?? "top20",
    meta: top20QVDMEgSsSmMeta || {},
    alias: top20QVDMEgSsSmMeta?.alias || [],
    redirect: top20QVDMEgSsSmMeta?.redirect,
    component: () => import("/usr/src/packages/stocks/pages/stocks/top20.vue").then(m => m.default || m)
  }
]
  },
  {
    name: callback0Nh93rez4dMeta?.name ?? "subscribe-callback___he",
    path: callback0Nh93rez4dMeta?.path ?? "/he/subscribe/callback",
    meta: callback0Nh93rez4dMeta || {},
    alias: callback0Nh93rez4dMeta?.alias || [],
    redirect: callback0Nh93rez4dMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/subscribe/callback.vue").then(m => m.default || m)
  },
  {
    name: callback0Nh93rez4dMeta?.name ?? "subscribe-callback___en",
    path: callback0Nh93rez4dMeta?.path ?? "/en/subscribe/callback",
    meta: callback0Nh93rez4dMeta || {},
    alias: callback0Nh93rez4dMeta?.alias || [],
    redirect: callback0Nh93rez4dMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/subscribe/callback.vue").then(m => m.default || m)
  },
  {
    name: termsofusel5AzRHu0H7Meta?.name ?? "termsofuse___he",
    path: termsofusel5AzRHu0H7Meta?.path ?? "/he/termsofuse",
    meta: termsofusel5AzRHu0H7Meta || {},
    alias: termsofusel5AzRHu0H7Meta?.alias || [],
    redirect: termsofusel5AzRHu0H7Meta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: termsofusel5AzRHu0H7Meta?.name ?? "termsofuse___en",
    path: termsofusel5AzRHu0H7Meta?.path ?? "/en/termsofuse",
    meta: termsofusel5AzRHu0H7Meta || {},
    alias: termsofusel5AzRHu0H7Meta?.alias || [],
    redirect: termsofusel5AzRHu0H7Meta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/termsofuse.vue").then(m => m.default || m)
  },
  {
    name: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.name ?? "trademark-copyright-intellectual-property___he",
    path: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.path ?? "/he/trademark-copyright-intellectual-property",
    meta: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta || {},
    alias: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.alias || [],
    redirect: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/trademark-copyright-intellectual-property.vue").then(m => m.default || m)
  },
  {
    name: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.name ?? "trademark-copyright-intellectual-property___en",
    path: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.path ?? "/en/trademark-copyright-intellectual-property",
    meta: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta || {},
    alias: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.alias || [],
    redirect: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/trademark-copyright-intellectual-property.vue").then(m => m.default || m)
  },
  {
    name: verify2fhYk5060fMeta?.name ?? "verify___he",
    path: verify2fhYk5060fMeta?.path ?? "/he/verify",
    meta: verify2fhYk5060fMeta || {},
    alias: verify2fhYk5060fMeta?.alias || [],
    redirect: verify2fhYk5060fMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: verify2fhYk5060fMeta?.name ?? "verify___en",
    path: verify2fhYk5060fMeta?.path ?? "/en/verify",
    meta: verify2fhYk5060fMeta || {},
    alias: verify2fhYk5060fMeta?.alias || [],
    redirect: verify2fhYk5060fMeta?.redirect,
    component: () => import("/usr/src/apps/base/src/pages/verify.vue").then(m => m.default || m)
  }
]
<template>
  <ClientOnly>
    <!-- // Desktop 'Product' SSR fallback button -->
    <template #fallback>
      <UiButton
        v-for="(action, index) in products.other"
        :class="cn('md:px-xs text-primary whitespace-pre px-0 py-0 leading-none')"
        :key="index"
        variant="list-item"
      >
        {{ action.label }}
      </UiButton>
      <UiButton class="md:px-xs text-primary whitespace-pre p-0 leading-none" variant="list-item">
        {{ t("products.invest.title") }}
      </UiButton>
    </template>

    <!-- // Mobile menu list items -->
    <template v-if="bp.isMobile.value">
      <li v-for="(action, index) in concatenatedProducts" :key="index">
        <UiButton
          :class="
            cn('md:px-xs text-primary whitespace-pre px-0 py-0 leading-none [&.active]:font-semibold', {
              'active bg-gray-50/50': isProductActive(concatenatedProducts, index),
            })
          "
          :id="genClickId('menu_link', index)"
          variant="list-item"
          :to="action?.to"
          @click="closeMenu"
        >
          {{ action.label }}
        </UiButton>
      </li>
    </template>

    <!-- // Desktop 'Product' menu popover -->
    <Fragment v-if="!bp.isMobile.value">
      <UiButton
        v-for="(action, index) in products.other"
        :class="
          cn(
            'text-primary whitespace-pre px-0 py-0 leading-none hover:font-normal hover:text-black/50 [&.active]:font-semibold',
            {
              active: isProductActive(products.other, index),
            }
          )
        "
        :key="index"
        :id="genClickId('menu_link', index)"
        variant="list-item"
        :to="action?.to"
        @click="closeMenu"
      >
        {{ action.label }}
      </UiButton>

      <!-- Invest Products -->
      <UiNavigationMenu
        :classes="{
          itemLink: 'px-5 leading-6',
          trigger:
            'md:px-xs text-primary whitespace-pre px-0 py-0 leading-none hover:bg-transparent hover:font-normal focus:bg-transparent',
        }"
        :items="products.invest"
      >
        <template #trigger>
          <UiButton
            :class="
              cn(
                'md:px-xs text-primary hover:background-none whitespace-pre bg-transparent px-0 py-0 text-base font-normal leading-none hover:text-black/50 data-[state=open]:bg-transparent',
                { active: isProductActive(products.invest) }
              )
            "
            variant="list-item"
          >
            {{ t("products.invest.title") }}
          </UiButton>
        </template>
      </UiNavigationMenu>
    </Fragment>
  </ClientOnly>
</template>

<script setup lang="ts">
import { tempReplaceLinkIfOwned } from "@finq/app-base/utils/common/product-utils"

import { type PlanCode, PricingProductEnum } from "@finq/pricing/types"

type ProductNavigationMenuLink = {
  id: keyof typeof PricingProductEnum
  screenPath: string
  label: string
  planCode: PlanCode
}

type MappedProductNavigationMenuLink = Omit<ProductNavigationMenuLink, "id"> & {
  id: string
  to?: string
}

const bp = useDisplay()
const emit = defineEmits<{ (e: "menu:close"): void }>()
const { t } = useI18n({ useScope: "local" })
const { tmsafe } = useI18nUtils({ useScope: "local" })

const showMenu = ref<boolean>(false)
const user = useUser()
const router = useRouter()

const mapProducts = (products: ProductNavigationMenuLink[]): MappedProductNavigationMenuLink[] => {
  return products.map((product, index) => {
    const prodCode = PricingProductEnum[product.id]

    return {
      ...product,
      id: genClickId("menu_link", prodCode, index),
      to: tempReplaceLinkIfOwned({
        productPlan: {
          productId: prodCode,
          code: product.planCode,
          screenPath: product.screenPath,
        },
        isProductOwnedByUser: user.canAccessProduct(prodCode),
      }),
    }
  })
}

const products = computed(() => {
  return {
    invest: mapProducts(tmsafe<ProductNavigationMenuLink[]>("products.invest.links")),
    other: mapProducts(tmsafe<ProductNavigationMenuLink[]>("products.links")),
  }
})

const concatenatedProducts = computed(() => [...products.value.other, ...products.value.invest])

const isProductActive = (
  product: MappedProductNavigationMenuLink | MappedProductNavigationMenuLink[],
  index?: number
) => {
  if (product && Array.isArray(product)) {
    if (!isNullOrUndefined(index) && typeof index === "number") {
      return router.currentRoute.value.path.includes(product[index].screenPath)
    }

    return product.some((p) => router.currentRoute.value.path.includes(p.screenPath))
  }

  return router.currentRoute.value.path.includes(product?.screenPath)
}

const closeMenu = () => {
  showMenu.value = false
  emit("menu:close")
}
</script>

<i18n lang="json">
{
  "en": {
    "products": {
      "links": [
        { "id": "Pensions", "label": "Pensions", "screenPath": "pension/depot" },
        { "id": "Stocks", "label": "Stocks", "screenPath": "stocks" }
      ],
      "invest": {
        "title": "Mutual Funds",
        "links": [
          { "label": "Mutual Funds Portal", "id": "Funds", "screenPath": "funds", "planCode": "DIY" },
          {
            "id": "Funds",
            "label": "Investment Portfolio Management",
            "screenPath": "kyc/INV",
            "planCode": "MANAGED"
          }
        ]
      }
    }
  },
  "he": {
    "products": {
      "links": [
        { "id": "Pensions", "label": "פנסיות", "screenPath": "pension/depot" },
        { "id": "Stocks", "label": "מניות", "screenPath": "stocks/portfolios" }
      ],
      "invest": {
        "title": "קרנות נאמנות",
        "links": [
          { "id": "Funds", "label": "פורטל קרנות נאמנות", "screenPath": "funds", "planCode": "DIY" },
          {
            "id": "Funds",
            "label": "ניהול תיק השקעות",
            "screenPath": "kyc/INV",
            "planCode": "MANAGED"
          }
        ]
      }
    }
  }
}
</i18n>

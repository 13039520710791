<template>
  <div :class="classNames.container">
    <button
      v-if="swiper?.enabled && !swiper.hideNavigation"
      :class="['tab-prev md:-start-s absolute start-0 top-1/2 z-30 -translate-y-1/2', navigationClasses.prev]"
    >
      <IconChevronUp class="rotate-90 ltr:-rotate-90" v-if="!bp.isMobile.value" filled :size="18" />
    </button>
    <component
      v-bind="{ ...(swiper?.enabled ? { ...swiperOptions, dir: isRtl ? 'rtl' : 'ltr' } : null) }"
      ref="containerRef"
      :is="swiper?.enabled ? 'swiper-container' : 'div'"
      :class="classNames.root"
    >
      <component
        v-for="(item, index) in list"
        :class="classNames.tabWrapper"
        :is="swiper?.enabled ? 'swiper-slide' : 'div'"
        :key="genUID()"
      >
        <UiSkeleton :loading="loading" :classes="{ line: classNames.skeleton, slot: 'h-full' }">
          <slot
            v-bind="{
              item,
              index,
              class: classNames.tab,
              attrs: tabAttrs(item, index),
            }"
            name="tab"
          >
            <UiButton v-bind="tabAttrs(item, index)" :class="classNames.tab">
              <slot v-bind="item">
                {{ item.label }}
              </slot>
            </UiButton>
          </slot>
        </UiSkeleton>
      </component>
    </component>
    <button
      v-if="swiper?.enabled && !swiper.hideNavigation"
      :class="['tab-next md:-end-s absolute end-0 top-1/2 z-30 -translate-y-1/2', navigationClasses.next]"
    >
      <IconChevronUp class="-rotate-90 ltr:rotate-90" v-if="!bp.isMobile.value" filled :size="18" />
    </button>
  </div>
</template>

<script lang="ts">
export interface UiTabsOption {
  id?: string
  to?: TypedRouteProps
  label?: string
  disabled?: boolean
  value: string | number | null
  onClick?: () => void
}
</script>

<script setup lang="ts" generic="T extends UiTabsOption">
import type { ClassValue } from "clsx"
import "swiper/css"
import { register } from "swiper/element/bundle"
import type { Swiper, SwiperOptions } from "swiper/types"

import { UiButtonProps } from "./Button.vue"

register()

interface UiTabsClasses {
  root: ClassValue
  tabWrapper: ClassValue
  container: ClassValue
  tab: ClassValue
  skeleton: ClassValue
}

type UiTabsAttributes = {
  tab: Partial<Omit<UiButtonProps, "variant" | "tab"> & HTMLButtonElement>
}

type TabVariants = { default: UiButtonProps["variant"]; active: UiButtonProps["variant"] }
type TabColors = { default: UiButtonProps["color"]; active: UiButtonProps["color"] }

type DynamicTab = (data: { selected?: boolean; item: T; index: number }) => UiTabsAttributes

const isDynamicTab = (tab?: DynamicTab | UiTabsAttributes): tab is DynamicTab => {
  return typeof tab === "function"
}

interface TabsProps {
  list: T[]
  exact?: boolean
  classes?: Partial<UiTabsClasses>
  attributes?: DynamicTab | UiTabsAttributes
  variants?: TabVariants
  colors?: TabColors
  swiper?: {
    enabled: boolean
    hideNavigation?: boolean
    options?: SwiperOptions
  }
  loading?: boolean
}

const props = withDefaults(defineProps<TabsProps>(), {
  variants() {
    return { default: "outline", active: "default" }
  },
})

const modelValue = defineModel<string | number>()
const emit = defineEmits<{ (e: "update:modelValue", value: T["value"]): void }>()

const id = useId()

const navigationClasses = {
  prev: `tab-prev-${id}`,
  next: `tab-next-${id}`,
}

const { isRtl } = useRtl()
const attrs = useAttrs()
const router = useRouterUtils()

const tabProps = (item: T) => {
  return {
    "data-selected": isTabActive(item),
    variant: getVariant(item),
    to: getToProp(item),
    disabled: item.disabled,
    onClick: () => handleClick(item),
    class: classNames.value.tab,
  }
}

const containerRef = ref<{ swiper: Swiper }>()
const bp = useDisplay()

const swiperOptions = computed((): SwiperOptions => {
  const initialSlide = props.list.findIndex((item) => item.value === modelValue.value)
  return {
    speed: 200,
    initialSlide,
    autoHeight: false,
    direction: "horizontal",
    navigation: {
      prevEl: `.${navigationClasses.prev}`,
      nextEl: `.${navigationClasses.next}`,
    },
    slidesPerView: "auto",
    keyboard: { enabled: true },
    centeredSlides: true,
    centeredSlidesBounds: true,
    centerInsufficientSlides: true,
    freeMode: true,
    ...props.swiper?.options,
  }
})

const classNames = computed(() => {
  return {
    container: cn("tabs-container relative", props.classes?.container),
    root: cn([
      props.swiper?.enabled ? "md:-mx-s w-auto" : "gap-s flex h-full",
      props.classes?.root,
      attrs.class!,
    ]),
    tab: cn("w-auto whitespace-pre", props.classes?.tab),
    skeleton: cn("h-9 rounded-full", props.classes?.skeleton),
    tabWrapper: cn([
      props.loading && props.swiper?.enabled ? "w-28" : "w-full lg:w-auto",
      { "px-xxxs md:first:ps-s md:last:pe-s first:ps-0 last:pe-0": props.swiper?.enabled },
      props.classes?.tabWrapper,
    ]),
  }
})

const handleClick = (item: T) => {
  if (item.disabled) return
  if (item.onClick) item.onClick?.()
  emit("update:modelValue", item.value)

  if (item.value) modelValue.value = item.value
}

const isRouteActive = (routeName?: RoutesNamesList): boolean => {
  if (!routeName) return false

  if (props.exact) return isRouteName(routeName, router.route.value.name)
  else
    return (
      router.route.value.matched.some((m) => isRouteName(routeName, m.name)) ||
      router.route.value.path.includes(routeName)
    )
}

const isTabActive = (item: T): boolean => {
  if (item.to) return isRouteActive(item.to.name)
  else return item.value === modelValue.value
}

const getToProp = (item: T): TypedRouteProps | undefined => {
  return item.to && !item.disabled ? item.to : undefined
}

const getVariant = (item: T): UiButtonProps["variant"] => {
  return isTabActive(item) ? props.variants.active : props.variants.default
}

const tabAttrs = (item: T, index: number) => {
  const base = { ...tabProps(item) }

  if (isDynamicTab(props.attributes)) {
    return { ...base, ...props.attributes({ selected: isTabActive(item), item, index }).tab }
  }

  return { ...base, ...props.attributes }
}

if (props.swiper?.enabled) {
  const initSlider = (list = props.list, value = modelValue.value) => {
    const initialSlide = list.findIndex((item) => item.value === value)
    containerRef.value?.swiper?.slideTo(initialSlide, 200, false)
  }

  const { width } = useWindowSize()

  const debouncedInitSlider = useDebounceFn(initSlider, 100)
  watch(width, () => debouncedInitSlider())

  watch(
    () => [modelValue.value, props.list],
    ([newModelValue, newList]) => {
      setTimeout(() => initSlider(newList as T[], newModelValue as string), 0)
    },
    { immediate: true }
  )
}
</script>

<style lang="scss" scoped>
.tabs-container {
  .tab-prev,
  .tab-next {
    width: auto;
    height: 100%;
    overflow: visible;
    opacity: 1;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;

    .finq-icon {
      display: block;
    }

    & > * {
      width: 100%;
    }
  }

  .tab-next {
    & > * {
      text-align: end;
    }
  }

  .tab-prev {
    & > * {
      text-align: start;
      margin-inline-end: auto;
    }
  }

  @screen md {
    .tab-prev,
    .tab-next {
      pointer-events: none;
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }

  .tab-next,
  .tab-prev {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      height: 40px;
      pointer-events: none;
      z-index: -5;
    }

    &.tab-prev {
      &::after {
        width: 150px;
        inset-inline-start: 0;
      }
    }

    &.tab-next {
      &::before {
        width: 150px;
        inset-inline-end: 0;
      }
    }

    &:dir(rtl) {
      &.tab-next {
        &::before {
          background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
        }
      }

      &.tab-prev {
        &::after {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
        }
      }
    }

    &:dir(ltr) {
      &.tab-next {
        &::before {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
        }
      }
      &.tab-prev {
        &::after {
          background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
        }
      }
    }
  }
}
</style>

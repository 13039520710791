import type { RouteLocation } from "#vue-router"

import type { DataLayerObject, DiscriminatedDataLayerArg } from "@finq/app-base/composables/analytics/useGtm"

const COOKIE_EXPIRES = 60 * 60 * 24 * 365

export default defineNuxtPlugin({
  name: "gtm",
  parallel: true,
  setup(nuxtApp) {
    const cfg = useAppConfig()
    const config = useRuntimeConfig()
    const enabled = useCookie<boolean | null>(cookiesConstants.cookiesAccepted, {
      expires: new Date(Date.now() + COOKIE_EXPIRES * 1000),
      default: () => null,
    })

    /** Gtag method will push data to the GTM dataLayer, but works specific types of events */
    function gtag(...args: (string | DataLayerObject)[]): void // Overload signature for gtag
    // prettier-ignore
    function gtag() { return window.dataLayer?.push(arguments) }

    function push(...args: DiscriminatedDataLayerArg[]) {
      if (isServer) return

      if (typeof args?.[0] !== "object") {
        return window.dataLayer?.push(args)
      } else {
        return window.dataLayer?.push({ ...args[0], event: args[0]?.event || "custom_event" })
      }
    }

    cfg.analytics?.enabled &&
      useHead({
        script: [
          // Google Tag Manager
          {
            tagPriority: "high",
            defer: true,
            async: true,
            innerHTML: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('consent', 'default', ${
                  (!cfg.analytics?.gtmAlwaysEnabled && enabled.value === null) || enabled.value === false
                    ? `{ 'ad_storage': 'denied', 'analytics_storage': 'denied', 'ad_user_data': 'denied', 'ad_personalization': 'denied' }`
                    : `{ 'ad_storage': 'granted', 'analytics_storage': 'granted', 'ad_user_data': 'granted', 'ad_personalization': 'granted' }`
                });`,
          },
          {
            // type: "text/partytown", -> bug with partytown track: https://github.com/BuilderIO/partytown/issues/72
            tagPriority: "high",
            defer: true,
            async: true,
            innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${config.public.NUXT_PUBLIC_ANALYTICS_GTM_ID}');`,
          },
        ],

        noscript: [
          // Google Tag Manager (noscript)
          {
            tagPosition: "bodyOpen",
            children: `<iframe src="https://www.googletagmanager.com/ns.html?id=${config.public.NUXT_PUBLIC_ANALYTICS_GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          },
        ],
      })

    function trackView(route: RouteLocation) {
      // Quits if is server or if GTM is not enabled
      if (isServer || enabled.value === false || (!cfg.analytics?.gtmAlwaysEnabled && !enabled.value)) return

      const removeTrailingSlash = (url: string) => (url.endsWith("/") ? url.slice(0, -1) : url)

      push({
        event: "page_view",
        page_name: stripRouteNameSuffix(route?.name),
        page_path: removeTrailingSlash(route?.fullPath),
        page_full_path: removeTrailingSlash(window?.location.href),
        language: nuxtApp.vueApp.$nuxt.$i18n.locale.value,
      })
    }

    watch(
      nuxtApp.vueApp.$nuxt.$router.currentRoute,
      (newRoute, oldRoute) => {
        if (newRoute.path !== oldRoute?.path) {
          trackView(newRoute as RouteLocation)
        }
      },
      { deep: true, immediate: true }
    )

    return {
      provide: {
        gtm: {
          enabled,
          push,
          trackView,
          gtag,
        },
      },
    }
  },
})

///

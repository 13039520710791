import revive_payload_client_65vNeqcVUX from "/usr/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_2kb3gpyadf3doc6frtdheajbri/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Kfo0NEyq26 from "/usr/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_2kb3gpyadf3doc6frtdheajbri/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cirewDntQL from "/usr/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_2kb3gpyadf3doc6frtdheajbri/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_UFxWlbII45 from "/usr/src/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.2_@unocss+reset@0.60.3_axios@1.7.2_floating-vue@5._xokuffthyj5p5gppgg6tlb5uxq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_kE9w1XEoip from "/usr/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_2kb3gpyadf3doc6frtdheajbri/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_WTIWApG9mw from "/usr/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_2kb3gpyadf3doc6frtdheajbri/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_sOezd3s34F from "/usr/src/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/apps/finq-finance/.nuxt/components.plugin.mjs";
import prefetch_client_d0eULfiDn4 from "/usr/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_2kb3gpyadf3doc6frtdheajbri/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_FSxFNt8ZsG from "/usr/src/node_modules/.pnpm/@vueuse+motion@2.2.3_rollup@4.18.0_vue@3.4.27_typescript@5.4.5_/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_ziYcKwnJdq from "/usr/src/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import i18n_scUw4hEEPg from "/usr/src/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.27_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_ADOHAxRfVq from "/usr/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_2kb3gpyadf3doc6frtdheajbri/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import eventBus_client_6G4UJ8ZbRL from "/usr/src/apps/base/src/plugins/eventBus.client.ts";
import formatHtml_g8oRQLd2uu from "/usr/src/apps/base/src/plugins/formatHtml.ts";
import gtm_faEZkqnVWN from "/usr/src/apps/base/src/plugins/gtm.ts";
import intersect_ellX5rVgT3 from "/usr/src/apps/base/src/plugins/intersect.ts";
import localStorage_MHY01ePQwD from "/usr/src/apps/base/src/plugins/localStorage.ts";
import vue_query_FsGdL7i3dD from "/usr/src/apps/base/src/plugins/vue-query.ts";
export default [
  revive_payload_client_65vNeqcVUX,
  unhead_Kfo0NEyq26,
  router_cirewDntQL,
  _0_siteConfig_UFxWlbII45,
  payload_client_kE9w1XEoip,
  check_outdated_build_client_WTIWApG9mw,
  plugin_vue3_sOezd3s34F,
  components_plugin_KR1HBZs4kY,
  prefetch_client_d0eULfiDn4,
  motion_FSxFNt8ZsG,
  plugin_ziYcKwnJdq,
  i18n_scUw4hEEPg,
  chunk_reload_client_ADOHAxRfVq,
  eventBus_client_6G4UJ8ZbRL,
  formatHtml_g8oRQLd2uu,
  gtm_faEZkqnVWN,
  intersect_ellX5rVgT3,
  localStorage_MHY01ePQwD,
  vue_query_FsGdL7i3dD
]
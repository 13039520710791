<template>
  <nav :class="classNames.root" :key="locale">
    <div :class="classNames.container">
      <UiButton
        class="h-fit p-0"
        v-if="withLogo"
        v-bind="logoAction"
        variant="link"
        :id="genClickId('nav', 'logo')"
      >
        <UiNuxtIcon filled name="finq-logo" :size="46" />
      </UiButton>

      <slot />
    </div>
  </nav>

  <div class="pointer-events-none -z-50 min-h-3" nav-spacer></div>
</template>

<script setup lang="ts">
import type { RouteMeta } from "#vue-router"
import type { ClassValue } from "clsx"

import type { UiClassesToAttributes } from "@finq/ui/types/common"

interface NavbarContainerClasses {
  root: ClassValue
  container: ClassValue
}

interface NavbarContainerProps {
  elevatedNavbar?: boolean
  classes?: Partial<NavbarContainerClasses>
  attributes?: UiClassesToAttributes<Partial<NavbarContainerClasses>>
  withLogo?: boolean
  navbarVariant?: NonNullable<RouteMeta["layoutProps"]>["navbarVariant"]
}

const props = defineProps<NavbarContainerProps>()

const classNames = computed(
  (): NavbarContainerClasses => ({
    root: cn([
      "z-60 pointer-events-auto fixed left-0 right-0 top-0 flex min-h-3 items-center bg-white transition-all duration-500",
      {
        "bg-transparent": props.navbarVariant === "transparent",
        "elevated-nav bg-white": props.elevatedNavbar,
      },
      props.classes?.root,
    ]),

    container: cn(["container flex h-full w-full items-center", props.classes?.container]),
  })
)

const route = useRoute()
const { scrollTo } = useScrollUtils()
const { locale } = useI18n()
const logoAction = computed(() => {
  if (isRouteName("index", route.name)) {
    return { onClick: () => scrollTo(0) }
  }

  return { prefetch: true, to: { name: "index" } as TypedRouteProps }
})
</script>

<style lang="scss" scoped>
// Copying the border style using box-shadow to allow animating the elevation
.elevated-nav {
  box-shadow: 0 1px 0 0 hsl(0 0% 0% / 6%);
}
</style>

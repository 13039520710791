<template>
  <!-- SEO: Leave Empty -->
</template>

<script setup lang="ts">
interface SEOProps {
  title: string
  shortName: string
  titleTemplate: string
  ogImage: string
  description: string | null
  localeCode: string
  url?: string | null
}

const route = useRoute()
const config = useRuntimeConfig()
const user = useUser()
const props = withDefaults(defineProps<Partial<SEOProps>>(), {
  title: "FINQ",
  shortName: "FINQ",
  ogImage: "/og-image.png",
  url: null,
})

const mappedUrl = computed(() => {
  if (props.url) return props.url
  if (route.path === "/") return config.NUXT_PUBLIC_SITE_URL

  return config.NUXT_PUBLIC_SITE_URL + route.path
})

const titleTemplate = computed(() => (props.titleTemplate === "" ? "%s" : "%s | " + props.titleTemplate))

useHead({ titleTemplate })

useSeoMeta({
  description: props.description, // TODO: Not reactive
  ogTitle: props.title,
  ogDescription: props.description,
  ogType: "website",
  ogUrl: mappedUrl.value,
  ogLocale: props.localeCode,
  ogSiteName: props.shortName,
  ogImage: props.ogImage,
  ogImageAlt: props.shortName,
  ogImageHeight: 314,
  ogImageWidth: 600,
  twitterCard: "summary_large_image",
  twitterImage: props.ogImage,
  twitterTitle: props.title,
  twitterDescription: props.description,
})

/**
 * Schema Org / JSON-LD
 */
useSchemaOrg([
  defineOrganization({
    name: props.shortName,
    url: config.NUXT_PUBLIC_SITE_URL,
    logo: props.ogImage,
    image: props.ogImage,
    sameAs: [],
  }),
  defineWebSite({
    name: props.shortName,
    url: config.NUXT_PUBLIC_SITE_URL,
    image: props.ogImage,
    inLanguage: props.localeCode,
  }),
  defineWebPage({
    name: props.title,
    url: mappedUrl.value,
  }),
])

/**
 * Hotjar attributes
 */
useSetHotJarAttributes(
  computed(() => ({
    "FINQ User ID": user.user.value?.id,
    "User Waiting list": user.user.value?.waitingListNotifications,
    "User Paid": user.user.value?.allowedProducts?.length! > 0,
    "Is in subscription page": ["pricing", "pricing-productId"].includes(stripRouteNameSuffix(route.name)),
    "Paid and returned from Stripe":
      stripRouteNameSuffix(route.name) === "subscribe-callback" && !!route.query?.checkoutSuccess,
  }))
)
</script>

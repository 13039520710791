<template>
  <div v-auto-animate>
    <div class="flex gap-2" v-if="!isAuthenticated && !isMobile">
      <UiButton class="font-medium" @click="login.open" :key="isHomePage + 'nav_login'" variant="outline">
        {{ $t("authentication.buttons.log_in") }}
      </UiButton>

      <UiButton @click="login.open({}, 'signup')">{{ $t("authentication.buttons.signup") }} </UiButton>
    </div>

    <LayoutUserMenu class="min-w-60" v-else :links />
  </div>
</template>

<script setup lang="ts">
import type { LayoutUserMenu } from "#components"

import { HeaderMenuLinks } from "./Navbar.vue"

defineProps<{ links?: HeaderMenuLinks }>()
const { isMobile } = useDisplay()
const route = useRoute()
const login = useLogin()
const { isAuthenticated } = useUser()
const isHomePage = computed(() => isRouteName("index", route.name))

const userMenuRef = ref<InstanceType<typeof LayoutUserMenu>>()
</script>

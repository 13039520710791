import { RouterTyped } from "#vue-router"

export const AuthPathMap = {
  login: "login",
  passwordresetawait: "waiting_confirmation",
  forgotpassword: "reset_password",
  signup: "signup",
  signupform: "signup_form",
  verify: "verify", // after signup
} as const

export type TAuthComponentsMap = keyof typeof AuthPathMap

export const AUTH_PAGES = Object.keys(AuthPathMap) as TAuthComponentsMap[]

export interface LoginCallback {
  success?: () => void
  close?: () => void
}

const loginState = reactive({
  isAuthModalOpen: false,
  lastVisitedAuthPage: undefined as TAuthComponentsMap | undefined,
  currentAuthPage: "login" as TAuthComponentsMap,
  authCallbacks: {} as LoginCallback,
  preloadedPopup: null as any,
})

export const useLogin = (options?: { router?: RouterTyped }) => {
  const { isAuthModalOpen, currentAuthPage, lastVisitedAuthPage, authCallbacks, preloadedPopup } =
    toRefs(loginState)
  const appConfig = useAppConfig()
  const $router = options?.router ?? useRouter()
  const user = useUser()
  const localePath = useLocalePath()
  const { replace, push, route } = useRouterUtils()

  const gtm = useGtm()
  const referringFeature = genReferringFeature()

  const defaultCallbacks: LoginCallback = {
    success() {
      let redirectionPath = isAuthPage($router.currentRoute.value.path) ? localePath("index") : undefined

      if (
        !user.user?.value?.allowedProducts?.length &&
        appConfig.features?.shouldNavigateToSubscribeAfterLogin
      ) {
        redirectionPath = localePath("pricing")
      }

      $router.replace(redirectionPath!).catch(() => {})
    },
    close() {},
  }

  function isAuthPage(pagePath: string) {
    const cleanedPagePath = stripRoutePathPrefix(pagePath).replace(/^\//, "")

    return AUTH_PAGES.includes(cleanedPagePath)
  }

  function navigateBack({ fallback = undefined }: { fallback: TAuthComponentsMap | undefined }) {
    changeAuthPage(lastVisitedAuthPage.value! || fallback)
  }

  function changeAuthPage(to: TAuthComponentsMap) {
    lastVisitedAuthPage.value = currentAuthPage.value
    currentAuthPage.value = to

    const resolvedNextPage = $router.resolve(localePath(to))
    const nextPageExists = resolvedNextPage.path !== route.value.path

    if (isAuthPage(route.value.path) && nextPageExists) {
      replace({ path: "/" + to })
    }

    gtm?.push({ event: "modal_open", action: to, referring_feature: referringFeature })
  }

  async function open(callbacks?: LoginCallback, openingPage = "login" as TAuthComponentsMap | undefined) {
    if (isAuthModalOpen.value) return
    else if (openingPage) currentAuthPage.value = openingPage
    isAuthModalOpen.value = true

    const userBrowser = await useUserBrowser()

    // Merge custom callbacks with default ones
    authCallbacks.value = Object.assign({}, defaultCallbacks, callbacks || {})

    // Safari blocks popups by default (for the first click only), so we need to preload the popup to avoid this.
    if (!preloadedPopup.value && userBrowser.isSafari) {
      preloadedPopup.value = await AuthService.preloadSocialPopup()
    }

    if (["login", "signup"].includes(currentAuthPage.value)) {
      gtm?.push({
        event: "modal_open",
        action: currentAuthPage.value,
        referring_feature: referringFeature,
      })
    }

    if (isRouteName("index", route.value.name)) {
      push(openingPage)
    }
  }

  const close = () => {
    if (!isAuthModalOpen.value) return

    isAuthModalOpen.value = false

    // Reset callbacks to default
    authCallbacks.value = defaultCallbacks
  }

  const afterClose = () => {
    if (isAuthModalOpen.value) return
    else if (currentAuthPage.value !== "login") currentAuthPage.value = "login"
  }

  const togglePopin = (state: boolean) => {
    const validState = state && typeof state === "boolean"

    isAuthModalOpen.value = validState ? state : !isAuthModalOpen.value
  }

  // Handling logins & signup
  const handleSuccess = () => {
    return new Promise((resolve) => {
      authCallbacks.value?.success?.()

      resolve({})

      close?.()
    })
  }

  const handleClose = () => {
    if (isAuthPage(route.value.path)) {
      replace({ path: "/" })
    } else {
      authCallbacks.value?.close?.()
    }
    // Close modal
    close()
  }

  return {
    loginState,

    isOpen: isAuthModalOpen,
    currPage: currentAuthPage,
    authCallbacks: authCallbacks,

    open,
    close,
    afterClose,
    togglePopin,
    isAuthPage,
    navigateBack,
    changeAuthPage,
    handleSuccess,
    handleClose,
  }
}

<template>
  <slot v-bind="api.triggerProps" name="trigger" />

  <ClientOnly>
    <UiPortal>
      <UiTransition name="dialog-transition" appear @after-leave="$emit('close')">
        <div
          v-if="api.open"
          v-bind="{ ...(attributes?.root as any) }"
          :class="classNames.root"
          :data-modal-id="instance?.uid"
        >
          <div
            v-bind="{ ...attributes?.backdrop, ...api.backdropProps }"
            :class="['dialog-backdrop', classNames.backdrop]"
            aria-hidden="true"
          />

          <div
            v-bind="{ ...attributes?.panelWrapper, ...api.positionerProps }"
            :class="classNames.panelWrapper"
          >
            <div
              v-bind="{ ...attributes?.panel, ...(api.contentProps as any) }"
              :class="['dialog-body', classNames.panel]"
            >
              <slot />
            </div>
          </div>
        </div>
      </UiTransition>
    </UiPortal>
  </ClientOnly>
</template>

<script lang="ts">
import type { Context } from "@zag-js/dialog"

import { UiClassesToAttributes } from "../../../types/common"

export interface UiBaseModalClasses {
  root: ClassValue
  backdrop: ClassValue
  panel: ClassValue
  panelWrapper: ClassValue
}

export interface DialogProps {
  id?: string
  modelValue: boolean
  class?: ClassValue
  classes?: Partial<UiBaseModalClasses>
  attributes?: UiClassesToAttributes<UiBaseModalClasses, false>
  persistent?: boolean
  backdrop?: VariantProps<typeof modalVariants>["backdrop"]

  /** Whether to prevent scrolling behind the dialog when it's opened */
  preventScroll?: Context["preventScroll"]
  /** Whether to close the dialog when the escape key is pressed */
  closeOnEscapeKeyDown?: Context["closeOnEscape"] | null
  /** Whether to close the dialog when the outside is clicked */
  closeOnInteractOutside?: Context["closeOnInteractOutside"] | null
  /** Element to receive focus when the dialog is closed */
  finalFocusEl?: Context["finalFocusEl"]
  /** Element to receive focus when the dialog is opened */
  initialFocusEl?: Context["initialFocusEl"]
  /** Whether to restore focus to the element that had focus before the dialog was opened */
  restoreFocus?: Context["restoreFocus"]
  /** Whether to trap focus inside the dialog when it's opened */
  trapFocus?: Context["trapFocus"]
  /** Whether to prevent pointer interaction outside the element and hide all content below it */
  modal?: Context["modal"]
}

export const modalVariants = cva("", {
  variants: {
    backdrop: {
      default: "fixed inset-0 bg-black/30",
      white: "backdrop-blur-sm fixed inset-0 bg-white/60 md:bg-black/30",
      none: "",
    },
  },
  defaultVariants: {
    backdrop: "default",
  },
})
</script>

<script setup lang="ts">
import * as dialog from "@zag-js/dialog"
import { normalizeProps, useMachine } from "@zag-js/vue"
import { VariantProps, cva } from "class-variance-authority"

const props = withDefaults(defineProps<DialogProps>(), {
  trapFocus: true,
  restoreFocus: true,
  preventScroll: true,
  closeOnEscapeKeyDown: null,
  closeOnInteractOutside: null,
})

const { modelValue, preventScroll } = toRefs(props)

const emit = defineEmits(["update:modelValue", "close", "esc", "outsideClick"])
const instance = getCurrentInstance()

const [state, send] = useMachine(
  dialog.machine({
    id: props.id ?? String(instance?.uid),
    open: modelValue.value,
    closeOnEscape: props.closeOnEscapeKeyDown ?? !props.persistent,
    closeOnInteractOutside: props.closeOnInteractOutside ?? !props.persistent,
    trapFocus: props.trapFocus,
    restoreFocus: props.restoreFocus,
    finalFocusEl: props.finalFocusEl,
    initialFocusEl: props.initialFocusEl,
    modal: preventScroll.value ? props.modal : false,

    // Do not use the `preventScroll` from the props
    preventScroll: false,

    onOpenChange: ({ open }) => {
      setIsOpen(open)
      if (!open) emit("close")
    },
    onEscapeKeyDown: () => {
      if (props.persistent) return

      emit("esc")
      emit("close")
      setIsOpen(false)
    },
    onInteractOutside: () => {
      if (props.persistent) return

      emit("outsideClick")
      emit("close")
      setIsOpen(false)
    },
  }),
  {
    context: computed(() => ({
      id: props.id ?? String(instance?.uid),
      open: props.modelValue,
      closeOnOutsideClick: !props.persistent,
      closeOnEscape: !props.persistent,
      trapFocus: props.trapFocus,
    })),
  }
)

const api = computed(() => dialog.connect(state.value, send, normalizeProps))

const html = document.getElementsByTagName("html")[0]
const isScrollLock = useScrollLock(html, props.modelValue && props.preventScroll)

const setIsOpen = (isOpen: boolean) => emit("update:modelValue", isOpen)

watch(
  () => [modelValue.value, preventScroll.value],
  ([isOpen, isScrollPrevented]) => {
    isScrollLock.value = isOpen && isScrollPrevented
  },
  { immediate: true }
)

const classNames = computed((): Required<UiBaseModalClasses> => {
  return {
    root: cn("z-60 fixed inset-0", { "pointer-events-none": !props.preventScroll }, props.classes?.root),
    backdrop: cn(modalVariants({ backdrop: props.backdrop }), props.classes?.backdrop),
    panelWrapper: cn(
      "z-1 p-xs fixed inset-0 end-0 flex items-center justify-center md:w-full md:items-end md:p-0",
      props.classes?.panelWrapper
    ),
    panel: cn(
      "max-w-large relative w-full overflow-hidden rounded-md bg-white shadow md:w-full md:max-w-none md:rounded-b-none",
      props.classes?.panel,
      props.class
    ),
  }
})
</script>

<style lang="scss" scoped>
.dialog-transition-enter-active,
.dialog-transition-leave-active {
  transition: all 0.3s ease-out;

  .dialog-backdrop {
    transition: all 0.2s ease-out;
  }

  .dialog-body {
    transition: all 0.3s ease-out;
    @media (max-width: theme("screens.md.max")) {
      // transition: all 5.5s ease;
    }
  }
}

// If you need a custom animation, you can use the following classes
.dialog-transition-enter-from,
.dialog-transition-leave-to {
  .dialog-backdrop {
    opacity: 0;
  }

  .dialog-body {
    opacity: 0;
    transform: scale(0.95);
    @media (max-width: theme("screens.md.max")) {
      transform: translateY(100%);
    }
  }
}
</style>

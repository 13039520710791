import { PricingProductEnum } from "./../types/index"
import { type MappedProduct } from "./store/usePricingStore"

export function useProducts() {
  const { tmsafe } = useI18nUtils({ useScope: "parent" })

  const products = computed(() => tmsafe("products") as MappedProduct)

  function isProductExist(product: PricingProductEnum) {
    return isKey(products.value, product)
  }

  return {
    products,
    isProductExist,
  }
}

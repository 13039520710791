import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIconAdd, LazyIconAlert, LazyIconAllFundsIcon, LazyIconArrowLeft, LazyIconBClose, LazyIconBOpen, LazyIconBookmark, LazyIconCactusNoData, LazyIconCaretDown, LazyIconCheckboxMixed, LazyIconCheckmarkV2, LazyIconCheckmark, LazyIconChevronDown, LazyIconChevronUp, LazyIconChevron, LazyIconCloseIconBurger, LazyIconCloseIconWhite, LazyIconCloseIcon, LazyIconClose, LazyIconComingSoon, LazyIconDownload, LazyIconError, LazyIconFilterIcon, LazyIconFilter, LazyIconFinqLogoFull, LazyIconFinqLogo, LazyIconFlagsFlagEuro, LazyIconFlagsFlagShekels, LazyIconFlagsFlagUsDollars, LazyIconGeographicalAS, LazyIconGeographicalAUS, LazyIconGeographicalEUR, LazyIconGeographicalISR, LazyIconGeographicalNA, LazyIconGeographicalROW, LazyIconGeographicalSA, LazyIconGeographicalUSA, LazyIconGlobe, LazyIconGraph, LazyIconGuestIcon, LazyIconHashtag, LazyIconMenu, LazyIconImportant, LazyIconInfoOutlined, LazyIconInfo, LazyIconLocale, LazyIconLockFilled, LazyIconLockIcon, LazyIconLock, LazyIconLogoFinqBeta, LazyIconMapsAS, LazyIconMapsAUS, LazyIconMapsEUR, LazyIconMapsISR, LazyIconMapsSA, LazyIconMapsUSA, LazyIconMuteOff, LazyIconMuteOn, LazyIconNote, LazyIconOffEye, LazyIconPin, LazyIconPlay, LazyIconPopupIconSuccess, LazyIconPopupIconWarning, LazyIconPortfolioDividerArrow, LazyIconPortfolioVerticalArrowDivider, LazyIconProfileBlockingSectionIcon, LazyIconProfileBlockingSectionWaitingForSaveIcon, LazyIconProfileCompletedIcon, LazyIconProfileCompletedWaitingForSaveIcon, LazyIconProfileLockedIcon, LazyIconProfileMissingIcon, LazyIconProfileWaitingIcon, LazyIconQuestionIconSmall, LazyIconRadio, LazyIconRankBurger, LazyIconSearch, LazyIconSettingsFilled, LazyIconSmallBurger, LazyIconSocialsFacebook, LazyIconSocialsGoogle, LazyIconStar, LazyIconSubscribeViewbox, LazyIconSuccess, LazyIconUnlock, LazyIconUploadId, LazyIconUser, LazyIconViewEye, LazyIconWarning } from '#components'
const lazyGlobalComponents = [
  ["IconAdd", LazyIconAdd],
["IconAlert", LazyIconAlert],
["IconAllFundsIcon", LazyIconAllFundsIcon],
["IconArrowLeft", LazyIconArrowLeft],
["IconBClose", LazyIconBClose],
["IconBOpen", LazyIconBOpen],
["IconBookmark", LazyIconBookmark],
["IconCactusNoData", LazyIconCactusNoData],
["IconCaretDown", LazyIconCaretDown],
["IconCheckboxMixed", LazyIconCheckboxMixed],
["IconCheckmarkV2", LazyIconCheckmarkV2],
["IconCheckmark", LazyIconCheckmark],
["IconChevronDown", LazyIconChevronDown],
["IconChevronUp", LazyIconChevronUp],
["IconChevron", LazyIconChevron],
["IconCloseIconBurger", LazyIconCloseIconBurger],
["IconCloseIconWhite", LazyIconCloseIconWhite],
["IconCloseIcon", LazyIconCloseIcon],
["IconClose", LazyIconClose],
["IconComingSoon", LazyIconComingSoon],
["IconDownload", LazyIconDownload],
["IconError", LazyIconError],
["IconFilterIcon", LazyIconFilterIcon],
["IconFilter", LazyIconFilter],
["IconFinqLogoFull", LazyIconFinqLogoFull],
["IconFinqLogo", LazyIconFinqLogo],
["IconFlagsFlagEuro", LazyIconFlagsFlagEuro],
["IconFlagsFlagShekels", LazyIconFlagsFlagShekels],
["IconFlagsFlagUsDollars", LazyIconFlagsFlagUsDollars],
["IconGeographicalAS", LazyIconGeographicalAS],
["IconGeographicalAUS", LazyIconGeographicalAUS],
["IconGeographicalEUR", LazyIconGeographicalEUR],
["IconGeographicalISR", LazyIconGeographicalISR],
["IconGeographicalNA", LazyIconGeographicalNA],
["IconGeographicalROW", LazyIconGeographicalROW],
["IconGeographicalSA", LazyIconGeographicalSA],
["IconGeographicalUSA", LazyIconGeographicalUSA],
["IconGlobe", LazyIconGlobe],
["IconGraph", LazyIconGraph],
["IconGuestIcon", LazyIconGuestIcon],
["IconHashtag", LazyIconHashtag],
["IconMenu", LazyIconMenu],
["IconImportant", LazyIconImportant],
["IconInfoOutlined", LazyIconInfoOutlined],
["IconInfo", LazyIconInfo],
["IconLocale", LazyIconLocale],
["IconLockFilled", LazyIconLockFilled],
["IconLockIcon", LazyIconLockIcon],
["IconLock", LazyIconLock],
["IconLogoFinqBeta", LazyIconLogoFinqBeta],
["IconMapsAS", LazyIconMapsAS],
["IconMapsAUS", LazyIconMapsAUS],
["IconMapsEUR", LazyIconMapsEUR],
["IconMapsISR", LazyIconMapsISR],
["IconMapsSA", LazyIconMapsSA],
["IconMapsUSA", LazyIconMapsUSA],
["IconMuteOff", LazyIconMuteOff],
["IconMuteOn", LazyIconMuteOn],
["IconNote", LazyIconNote],
["IconOffEye", LazyIconOffEye],
["IconPin", LazyIconPin],
["IconPlay", LazyIconPlay],
["IconPopupIconSuccess", LazyIconPopupIconSuccess],
["IconPopupIconWarning", LazyIconPopupIconWarning],
["IconPortfolioDividerArrow", LazyIconPortfolioDividerArrow],
["IconPortfolioVerticalArrowDivider", LazyIconPortfolioVerticalArrowDivider],
["IconProfileBlockingSectionIcon", LazyIconProfileBlockingSectionIcon],
["IconProfileBlockingSectionWaitingForSaveIcon", LazyIconProfileBlockingSectionWaitingForSaveIcon],
["IconProfileCompletedIcon", LazyIconProfileCompletedIcon],
["IconProfileCompletedWaitingForSaveIcon", LazyIconProfileCompletedWaitingForSaveIcon],
["IconProfileLockedIcon", LazyIconProfileLockedIcon],
["IconProfileMissingIcon", LazyIconProfileMissingIcon],
["IconProfileWaitingIcon", LazyIconProfileWaitingIcon],
["IconQuestionIconSmall", LazyIconQuestionIconSmall],
["IconRadio", LazyIconRadio],
["IconRankBurger", LazyIconRankBurger],
["IconSearch", LazyIconSearch],
["IconSettingsFilled", LazyIconSettingsFilled],
["IconSmallBurger", LazyIconSmallBurger],
["IconSocialsFacebook", LazyIconSocialsFacebook],
["IconSocialsGoogle", LazyIconSocialsGoogle],
["IconStar", LazyIconStar],
["IconSubscribeViewbox", LazyIconSubscribeViewbox],
["IconSuccess", LazyIconSuccess],
["IconUnlock", LazyIconUnlock],
["IconUploadId", LazyIconUploadId],
["IconUser", LazyIconUser],
["IconViewEye", LazyIconViewEye],
["IconWarning", LazyIconWarning],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

<template>
  <LayoutHtmlBody>
    <LayoutNavbar
      v-if="!$route.meta.layoutProps?.hideNavbar"
      :back-button="$route.meta.layoutProps?.backButton"
      :hide-user-actions="$route.meta.layoutProps?.hideUserActions"
      :close-button="$route.meta.layoutProps?.closeButton"
      :hide-links="$route.meta.layoutProps?.hideLinks"
    />

    <NuxtLayout>
      <!-- // Add more global components here -->
      <ClientOnly>
        <NuxtLazyHydrate :when-idle="4000">
          <LazyAuthWidget />
          <LazyLayoutAppWidgetWrapper />
          <LazyLayoutCookiesModal />
          <LazyUiSnackbarFactory />
          <LazyUiDialogFactory />
        </NuxtLazyHydrate>
      </ClientOnly>

      <NuxtLoadingIndicator :throttle="800" />

      <NuxtPage />
    </NuxtLayout>

    <LayoutFooter
      class="mt-auto"
      v-if="!$route.meta.layoutProps?.hideFooter"
      :appLayout="dataByLang.app_layout"
    />
  </LayoutHtmlBody>
</template>

<script setup lang="ts">
// Fetch application layout data once
const { dataByLang } = await useContentfulPage("app_layout")

// Initialize the app
useAppInit()
</script>

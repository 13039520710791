<template>
  <NuxtLazyHydrate v-if="!bp.isMobile.value" :when-idle="4000">
    <UiPopover
      v-model="isMenuOpen"
      :collision-padding="bp.lg.value ? 80 : 0"
      :id="genClickId('user-menu')"
      :classes="{
        body: 'pointer-events-auto min-w-56 origin-top-left overflow-hidden shadow-lg focus:outline-none rtl:origin-top-right',
      }"
    >
      <template #trigger>
        <LayoutUserMenuButton :show-menu="isMenuOpen" />
      </template>

      <LazyLayoutUserMenuListItems v-if="!bp.isMobile.value" v-model="isMenuOpen" :links="links!" />
    </UiPopover>
  </NuxtLazyHydrate>

  <!-- Mobile Modal -->
  <UiBaseModal
    class="h-[100dvh] rounded-none bg-white/95 backdrop-blur-md"
    v-else
    v-model="isMenuOpen"
    :id="genClickId('user-menu')"
    :classes="{ root: 'user-menu-modal z-50' }"
    :trap-focus="false"
    @close="close"
  >
    <template #trigger="{ dir, ...triggerProps }">
      <LayoutUserMenuButton v-bind="triggerProps" :isMenuOpen />
    </template>

    <div class="pb-xl flex h-full flex-col pt-16">
      <LazyLayoutUserMenuListItems v-model="isMenuOpen" :links="links!" />

      <LayoutLanguageSwitcher
        class="px-xxs mx-4 mt-auto flex items-center justify-start gap-1"
        show-text
        @click="close"
      />
    </div>
  </UiBaseModal>
</template>

<script setup lang="ts">
// LayoutUserMenu.vue
import { HeaderMenuLinks } from "../Navbar.vue"

defineProps<{ links?: HeaderMenuLinks }>()

const bp = useDisplay()
const { width } = useWindowSize()
const isMenuOpen = ref<boolean>(false)

const close = () => (isMenuOpen.value = false)

watch(
  () => [width.value],
  () => {
    if (isMenuOpen.value) close()
  },
  { immediate: true, deep: true }
)

defineExpose({ close, isMenuOpen })
</script>

<style lang="scss">
@media (max-width: theme("screens.md.max")) {
  .user-menu-modal {
    &.dialog-transition-enter-from,
    &.dialog-transition-leave-to {
      .dialog-body {
        .app-ltr & {
          transform: translateX(-100%);
        }
        .app-rtl & {
          transform: translateX(100%);
        }
      }
    }
  }
}
</style>

import { GlobalAppConfigInput } from "../../../lib/global"

export default defineAppConfig<GlobalAppConfigInput>({
  app: {
    domain: "finqai.co.il",
  },
  i18n: {
    multiLanguage: true,
    defaultLocale: "he",
  },
  analytics: {
    gtmAlwaysEnabled: false,
  },
  payments: {
    service: "payme",
  },
  features: {
    heroCtaPriorities: [
      { productId: "PRODUCT_PENSION", planCode: "DIY", route: "pension-depot" },
      { productId: "PRODUCT_STOCK", planCode: "DIY", route: "stocks" },
      { productId: "PRODUCT_FUND", planCode: "MANAGED", route: "my_investments" },
    ],
    showFullPersonalProfileInfo: true,
    showSubscriptionProfileSection: true,
    profileSubscriptionSectionRoute: "pricing",
  },
} satisfies GlobalAppConfigInput)

import { type InferOutput, array, enum_, nullish, number, object, string } from "valibot"

export const PortfolioType = {
  Long: "long10",
  Short: "short10",
  LongShort: "longShort20",
  Snp: "snp500",
} as const

export type PortfolioType = (typeof PortfolioType)[keyof typeof PortfolioType]

export const stocksPortfolioTypesSchema = object({
  id: enum_(PortfolioType),
  textHe: string(),
  textEn: string(),
  nameHe: string(),
  nameEn: string(),
  yield: nullish(number()),
  assetCount: number(),
  displayOrder: nullish(number()),
  accumulatedYield: nullish(number()),
  comparedAccumulatedYield: nullish(number()),
  comparedPortfolioId: nullish(string()),
})

export type StocksPortfolioTypeWithAdditional = InferOutput<typeof stocksPortfolioTypesSchema>

export type MappedStocksPortfolioType = StocksPortfolioTypeWithAdditional & {
  name: string
  image: string
  to?: TypedRouteProps
}
export namespace StockComparison {
  export const datasetSchema = object({
    yield: number(),
    date: number(),
  })
  export type Dataset = InferOutput<typeof datasetSchema>

  export const chartSchema = object({
    id: string(),
    datasets: array(datasetSchema),
  })
  export type Chart = InferOutput<typeof chartSchema>

  export const schema = object({
    lastUpdateDate: nullish(number()),
    charts: array(chartSchema),
  })
}

export type StockComparison = InferOutput<typeof StockComparison.schema>

export namespace PortfolioComparison {
  export const COLORS = {
    [PortfolioType.Long]: ["rgba(15, 127, 220, 1)", "rgba(255, 255, 255, 0.3)"],
    [PortfolioType.Short]: ["rgba(151, 83, 243, 1)", "rgba(255, 255, 255, 0.3)"],
    [PortfolioType.LongShort]: ["rgba(255, 155, 0, 1)", "rgba(255, 255, 255, 0.3)"],
    [PortfolioType.Snp]: ["rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 0)"],
  }

  export const BORDER_COLORS = {
    [PortfolioType.Long]: "rgba(15, 127, 220, 1)",
    [PortfolioType.Short]: "rgba(151, 83, 243, 1)",
    [PortfolioType.LongShort]: "rgba(255, 155, 0, 1)",
    [PortfolioType.Snp]: "rgba(114, 114, 114, 1)",
  }

  export const NAMES: { [key in PortfolioType]: string } = {
    [PortfolioType.Snp]: "FINQFULL",
    [PortfolioType.Long]: "FINQFIRST",
    [PortfolioType.Short]: "FINQLAST",
    [PortfolioType.LongShort]: "FINQNEUTRAL",
  }

  export const IMAGES: { [key in PortfolioType]: string } = {
    [PortfolioType.Snp]: "/stocks/snp500.png",
    [PortfolioType.Long]: "/stocks/long.png",
    [PortfolioType.Short]: "/stocks/short.png",
    [PortfolioType.LongShort]: "/stocks/long-short.png",
  }
}

export const BenchmarkType = {
  Snp: "snp500",
  Short: "snp500short",
  MarketNeutral: "naturalIndex",
} as const

export type BenchmarkType = (typeof BenchmarkType)[keyof typeof BenchmarkType]

export function mapListToTypedCards<T extends Array<Record<string, any>>>(data: T | undefined) {
  if (!data?.length) return []

  return (
    data?.map((type) => {
      const typeId = type.id as PortfolioType
      const SNP = typeId === PortfolioType.Snp

      return {
        ...type,
        name: PortfolioComparison.NAMES[typeId] || PortfolioComparison.NAMES[PortfolioType.Long],
        image: PortfolioComparison.IMAGES[typeId] || PortfolioComparison.IMAGES[PortfolioType.Long],
        to: SNP
          ? { name: "stocks-portfolios-snp500" }
          : { name: "stocks-portfolios-portfolioType", params: { portfolioType: type.id } },
      }
    }) || []
  )
}

<script setup lang="ts">
import { cva } from "class-variance-authority"
import { ChevronDown } from "lucide-vue-next"
import { NavigationMenuTrigger, type NavigationMenuTriggerProps, useForwardProps } from "radix-vue"

const navigationMenuTriggerStyle = cva(
  "bg-background hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground data-[active]:bg-accent/50 data-[state=open]:bg-accent/50 group inline-flex h-10 w-max items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors focus:outline-none disabled:pointer-events-none disabled:opacity-50"
)

const props = defineProps<NavigationMenuTriggerProps & { class?: ClassValue; withIcon?: boolean }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <NavigationMenuTrigger
    v-bind="forwardedProps"
    :class="cn(navigationMenuTriggerStyle(), 'group', props.class)"
  >
    <slot />
    <ChevronDown
      class="relative top-px ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
      v-if="withIcon"
      aria-hidden="true"
    />
  </NavigationMenuTrigger>
</template>

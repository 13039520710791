<template>
  <LayoutFooterItemsList :title="t('title')" :items="products" />
</template>

<script setup lang="ts">
import { FooterItem } from "@finq/app-base/components/layout/Footer/Footer.vue"

const { t } = useI18n({ useScope: "local" })
const { tmsafe } = useI18nUtils({ useScope: "local" })

const products = computed(() => tmsafe<FooterItem[]>("items"))
</script>

<i18n lang="json">
{
  "en": {
    "title": "Products",
    "items": [
      { "title": "Stocks", "to": { "name": "stocks" } },
      { "title": "Pension", "to": { "name": "pension-depot" } },
      { "title": "Funds", "to": { "name": "funds" } }
    ]
  },
  "he": {
    "title": "Products",
    "items": [
      { "title": "מניות", "to": { "name": "stocks" } },
      { "title": "פנסיות", "to": { "name": "pension-depot" } },
      { "title": "קרנות נאמנות", "to": { "name": "funds" } }
    ]
  }
}
</i18n>
